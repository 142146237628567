import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Task } from 'src/app/feature-modules/building-permit/state/models/tabs.model';
import { AppSelectors } from 'src/app/global/state';
import {TaskTemplate} from 'src/app/global/state/models/workflow/taskTemplate';
import {WorkflowInstance} from 'src/app/global/state/models/workflow/workflowInstance';
import {WorkflowTemplate} from 'src/app/global/state/models/workflow/workflowTemplate';

@Component({
  selector: 'app-workflow-container',
  templateUrl: './workflow-container.component.html',
  styleUrls: ['./workflow-container.component.scss'],
})

export class WorkflowContainerComponent implements OnInit, OnDestroy {

  workflowInstance$: Observable<WorkflowInstance>;
  availableTasks$: Observable<TaskTemplate[]>;
  // workflowTemplate: WorkflowTemplate;
  // initialTask: TaskTemplate;
  subs: Subscription[] = [];

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.workflowInstance$ = this.store.select(AppSelectors.getActiveWorkflowInstance);
    this.availableTasks$ = this.store.select(AppSelectors.getAvailableWorkflowTasks);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subs.forEach(sub => sub.unsubscribe());
  }


}
