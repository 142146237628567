import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ParkingTicketsSearchComponent } from './parking-tickets-unauthenticated/parking-tickets-search/parking-tickets-search.component';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PtEffects } from './parking-tickets-unauthenticated/state/pt.effects';
import { ptReducer, ptState } from './parking-tickets-unauthenticated/state/pt.reducer';
import { RouterModule, Routes } from '@angular/router';
import { SelectCityComponent } from '../global/components/select-city/select-city.component';
import { ShoppingCartUnauthenticatedComponent } from './shopping-cart-unauthenticated/shopping-cart-unauthenticated.component';
import { ReceiptsUnauthenticatedComponent } from './receipts-unauthenticated/receipts-unauthenticated.component';
import { ParkingTicketReceiptsComponent } from './parking-tickets-unauthenticated/parking-ticket-receipts/parking-ticket-receipts.component';

const routes: Routes = [
  {
      path: '', component: ParkingTicketsSearchComponent,
      //canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'shopping-cart-unauthenticated',
    component: ShoppingCartUnauthenticatedComponent
  }
];

@NgModule({
  declarations: [
    ParkingTicketsSearchComponent, 
    ShoppingCartUnauthenticatedComponent, 
    ReceiptsUnauthenticatedComponent,
    ParkingTicketReceiptsComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(ptState, ptReducer),
    EffectsModule.forFeature([PtEffects])
  ]
})
export class UnauthenticatedModule { }
