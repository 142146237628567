import { ActionReducer, INIT, MetaReducer } from "@ngrx/store";

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        // console.log('state', state);
        // console.log('action', action);

        return reducer(state, action);
    };
}

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
    return function clearStateFn(state, action) {
        if (action.type === '[app] - clear state') {
            state = {}; // ==> Emptying state here
        }
        return reducer(state, action);
    };
}

export const hydrationMetaReducer = (
    reducer: ActionReducer<any>
): ActionReducer<any> => {
    return (state, action) => {
        if (action.type === INIT) {
            const storageValue = localStorage.getItem("state");
            if (storageValue) {
                try {
                    // return JSON.parse(storageValue);
                    return JSON.parse(atob(storageValue));
                } catch {
                    localStorage.removeItem("state");
                }
            }
        }
        const nextState = reducer(state, action);
        // localStorage.setItem("state", JSON.stringify(nextState));
        localStorage.setItem("state", btoa(JSON.stringify(nextState)));
        return nextState;
    };
};

export const metaReducers: MetaReducer<any>[] = [debug, logout, hydrationMetaReducer];
