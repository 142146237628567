import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// angular material imports
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// angular fire imports
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

// forms imports
import { ReactiveFormsModule } from '@angular/forms';

// formio imports
import { FormioModule } from '@formio/angular';

// Algolia
// import { NgAisModule } from 'angular-instantsearch';

//
import { FlexLayoutModule } from '@angular/flex-layout';

import { HttpClientModule } from '@angular/common/http';

import { SpinnerComponent } from '../global/components/spinner/spinner.component';

// workflow components
import { WorkflowContainerComponent } from '../global/components/workflow/workflow-container/workflow-container.component';
import { ApprovalTaskComponent } from '../global/components/workflow/approval-task/approval-task.component';
import { FormTaskComponent } from '../global/components/workflow/form-task/form-task.component';
import { UploadTaskComponent } from '../global/components/workflow/upload-task/upload-task.component';
import { ConfirmationTaskComponent } from '../global/components/workflow/confirmation-task/confirmation-task.component';
import { PaymentTaskComponent } from '../global/components/workflow/payment-task/payment-task.component';
import { TaskContainerComponent } from '../global/components/workflow/task-container/task-container.component';
import { ValidationTaskComponent } from '../global/components/workflow/validation-task/validation-task.component';
import { MatDialogModule } from '@angular/material/dialog';

const modules = [
  CommonModule,
  MatButtonModule,
  MatToolbarModule,
  LayoutModule,
  MatSidenavModule,
  MatListModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  AngularFireAuthModule,
  AngularFireAuthGuardModule,
  AngularFirestoreModule,
  AngularFireStorageModule,
  MatButtonToggleModule,
  MatTabsModule,
  MatStepperModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  AngularFireFunctionsModule,
  FlexLayoutModule,
  MatBadgeModule,
  MatTableModule,
  FormioModule,
  HttpClientModule,
  MatExpansionModule,
  MatProgressBarModule
];

const components = [
  SpinnerComponent,
  WorkflowContainerComponent,
  TaskContainerComponent,
  ApprovalTaskComponent,
  FormTaskComponent,
  UploadTaskComponent,
  ConfirmationTaskComponent,
  PaymentTaskComponent,
  ValidationTaskComponent
]

@NgModule({
  declarations: [...components],
  imports: [
    ...modules,
  ],
  exports: [
    ...modules,
    ...components
  ]
})

export class SharedModule { }