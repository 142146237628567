import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppSelectors } from 'src/app/global/state';
import { Receipt, CscStyles, CscClient } from 'src/app/global/state/models/models';

@Component({
  selector: 'app-print-receipt',
  templateUrl: './print-receipt.component.html',
  styleUrls: ['./print-receipt.component.scss']
})
export class PrintReceiptComponent implements OnInit, OnDestroy {

  receipt$: Observable<Receipt>;
  styles$: Observable<CscStyles>;
  city: CscClient;
  subscriptions: Subscription[] = [];
  logoImageUrl: Observable<string>;

  constructor(
    private store: Store,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    this.styles$ = this.store.select(AppSelectors.getStyles);
    this.receipt$ = this.store.select(AppSelectors.getReceiptToPrint);
    const subscription1 = this.store.select(AppSelectors.getLogoRef)
      .subscribe(ref => {
        ref ? this.logoImageUrl = this.storage.ref(ref).getDownloadURL() : {};
      })
    this.subscriptions.push(subscription1);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
