import { createReducer, on } from '@ngrx/store';
import { ReceiptPrintService } from 'src/app/global/services/receipt-print.service';
import { Invoice, Receipt, SubledgerAccount, Tab } from 'src/app/global/state/models/models';
import * as PtActions from './pt.actions';

export interface PtState {
    ptSearchInvoices: Invoice[],
    ptReceipt?: Receipt,
    ptReceipts: Receipt[],
    error: string;
}

const initialState: PtState = {
    ptSearchInvoices: [],
    ptReceipts: [],
    ptReceipt: null,
    error: ''
}

export const ptState = 'ptState';

export const ptReducer = createReducer<PtState>(
    initialState,
    on(PtActions.loadParkingTicketsByTicketNumberSuccess, (state, action): PtState => {
        return {
            ...state,
            ptSearchInvoices: action.invoices
        }
    }),
    on(PtActions.loadParkingTicketsByTicketNumberFailure, (state, action): PtState => {
        return {
            ...state,
            error: action.error
        }
    }),
    on(PtActions.loadParkingTicketsByLicensePlateSuccess, (state, action): PtState => {
        return {
            ...state,
            ptSearchInvoices: action.invoices
        }
    }),
    on(PtActions.loadParkingTicketsByLicensePlateFailure, (state, action): PtState => {
        return {
            ...state,
            error: action.error
        }
    }),
    on(PtActions.updateParkingTicketssAfterSuccessfulPayment, (state, action): PtState => {
        let invoices: Invoice[] = [...state.ptSearchInvoices];
        action.receipts.forEach(receipt => {
            let invoiceToUpdateIndex = state.ptSearchInvoices.findIndex(invoice => invoice._id == receipt.cartItemInvoiceIdToPay);
            let invoiceToUpdate = state.ptSearchInvoices[invoiceToUpdateIndex];
            invoiceToUpdate = {
                ...invoiceToUpdate,
                invoiceAmountPaid: receipt.cartItemAmountToPay,
            };
            invoices.splice(invoiceToUpdateIndex, 1);
            invoices.push(invoiceToUpdate);
        });
        return {
            ...state,
            ptSearchInvoices: invoices
        }
    }),
    on(PtActions.loadParkingTicketReceiptsSuccess, (state, action): PtState => {
        return {
            ...state,
            ptReceipts: action.receipts
        }
    }),    
    on(PtActions.loadParkingTicketsByLicensePlateFailure, (state, action): PtState => {
        return {
            ...state,
            error: action.error
        }
    }),
    on(PtActions.loadParkingTicketReceipt, (state, action): PtState => {
        return {
            ...state,
            ptReceipt: action.receipt
        }
    }),
    on(PtActions.resetParkingTicketState, (state, action): PtState => {
        return {
            ...state,
            ptReceipts: [],
            ptSearchInvoices: [],
            ptReceipt: null,
            error: ''
        }
    }),
)