<div class="app-page">
    <div class="title">
        Your Shopping Cart
    </div>

    <ng-container *ngIf="(shoppingCart$ | async)?.shoppingCartItems.length == 0">
        <div>Your cart is empty</div>
    </ng-container>

    <ng-container *ngIf="(shoppingCart$ | async)?.shoppingCartItems.length > 0">
        <table id="cart">
            <thead [ngStyle]="(styles$|async)?.background">
                <tr>
                    <th>Description</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of (shoppingCart$ | async)?.shoppingCartItems">
                    <td>
                        <p>{{item.cartItemDescription}}</p>
                        <p>{{item.cartItemInvoiceIdToPay}}</p>
                        <p class="remove-item" (click)="removeItemFromCart(item)">(remove item)</p>
                    </td>
                    <td class="text-right vertical-middle">{{item.cartItemAmountToPay / 100 | currency}}</td>
                </tr>
                <tr>
                    <td class="text-right">Subtotal:</td>
                    <td class="text-right">{{ (shoppingCart$ | async)?.shoppingCartSubtotalAmount / 100 | currency
                        }}
                    </td>
                </tr>
                <ng-container *ngIf="(shoppingCart$ | async)?.shoppingCartTotalConvenienceFees > 0">
                    <tr>
                        <td class="text-right">Convenience Fee:</td>
                        <td class="text-right">{{ (shoppingCart$ | async)?.shoppingCartTotalConvenienceFees / 100 |
                            currency
                            }}
                        </td>
                    </tr>
                </ng-container>
                <tr>
                    <td class="text-right">Total Amount:</td>
                    <td class="text-right">{{ (shoppingCart$ | async)?.shoppingCartTotalAmount / 100 | currency }}
                    </td>
                </tr>
            </tbody>
        </table>

        <form [formGroup]="checkoutForm" (submit)="onSubmit($event)">
            <div>
                <div class="stripe-field" #cardElement (submit)="onSubmit($event)">
                    <!-- A Stripe Element will be inserted here. -->
                </div>
                <!-- Used to display Element errors. -->
                <p>{{ cardErrors }}</p>
                <button [ngStyle]="(styles$|async)?.button" [disabled]="isCheckoutActive">
                    Checkout for {{(shoppingCart$ | async).shoppingCartTotalAmount / 100 | currency}}
                </button>
            </div>
        </form>

        <div>
            {{stripeElementsMessage$| async}}
        </div>

        <ng-container *ngIf="showPaymentSpinner">
            <div class="form-container">
                <app-spinner></app-spinner>
            </div>
        </ng-container>
    </ng-container>
</div>