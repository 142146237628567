import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CartItem, Citizen, CscStyles, FirebaseUser, Receipt, ShoppingCart } from 'src/app/global/state/models/models';
import * as AppSelectors from 'src/app/global/state/app.selectors';
import * as AppActions from 'src/app/global/state/app.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { StripeCoreService } from '../../services/stripe-core.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { TxActions } from 'src/app/feature-modules/property-taxes/state';
import { Router } from '@angular/router';
import { CscClient } from 'src/app/global/state/models/models';
import { UbActions, UbSelectors } from 'src/app/feature-modules/utility-billing/state';
import { StripeCoreService } from 'src/app/global/services/stripe-core.service';
import { PtActions } from '../parking-tickets-unauthenticated/state';
import { EmailService } from '../services/email.service';

declare var Stripe;

@Component({
  selector: 'app-shopping-cart-unauthenticated',
  templateUrl: './shopping-cart-unauthenticated.component.html',
  styleUrls: ['./shopping-cart-unauthenticated.component.scss']
})
export class ShoppingCartUnauthenticatedComponent implements OnInit, OnDestroy {

  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  stripe;
  stripeAccountId: string;
  card;
  cardErrors;
  checkoutForm: FormGroup;
  stripeElementsMessage$ = new BehaviorSubject(' ');
  showPaymentSpinner: boolean = false;

  shoppingCart$: Observable<ShoppingCart>;
  shoppingCart: ShoppingCart;
  cityContext: CscClient;
  cityInfo$: Observable<CscClient>;
  user: FirebaseUser;
  styles$: Observable<CscStyles>;
  subscriptions: Subscription[] = [];
  isCheckoutActive: boolean = false;
  receipt: Receipt;
  style: CscStyles;

  constructor(private store: Store,
    private fb: FormBuilder,
    private fxns: AngularFireFunctions,
    private stripeCore: StripeCoreService,
    private cdr: ChangeDetectorRef,
    public emailService: EmailService,
    private router: Router) {
    //let clientCode: string = localStorage.getItem('code');
    //console.log("Client code is ",clientCode);
    //this.store.dispatch(AppActions.loadCityAccount({ cityClientCode: clientCode }));

    this.shoppingCart$ = this.store.select(AppSelectors.getShoppingCart);
    this.styles$ = this.store.select(AppSelectors.getStyles);

    let cityContextSubscription = this.store.select(AppSelectors.getCityContext).subscribe(cityContext => {
      this.cityContext = cityContext;
      this.stripeAccountId = cityContext.account;
    });
    this.subscriptions.push(cityContextSubscription);
    let userSubscription = this.store.select(AppSelectors.getFirebaseUser).subscribe(user => this.user = user);
    this.subscriptions.push(userSubscription);
    let accountSubscription = this.store.select(AppSelectors.getCityAccount).subscribe(account => this.stripeAccountId = account);
    this.subscriptions.push(accountSubscription);
    this.cityInfo$ = this.store.select(AppSelectors.getCityContext);

    let subscriptionStyle = this.styles$.subscribe(style => this.style = style);
    this.subscriptions.push(subscriptionStyle);
  }

  ngOnInit(): void {
    this.checkoutForm = this.fb.group({
      receiptEmail: ['', Validators.required],
      checkoutTotal: [0]
    });

    let shoppingCartSubscription = this.shoppingCart$.subscribe(cart => this.shoppingCart = cart);
    this.subscriptions.push(shoppingCartSubscription);

    let cityContextSubscriptionAccount = this.store.select(AppSelectors.getCityContext).subscribe(cityContext => {
      this.cityContext = cityContext;
      this.stripeAccountId = cityContext.account;
    });
    this.subscriptions.push(cityContextSubscriptionAccount);
  }

  ngAfterViewInit(): void {
    if (this.shoppingCart.shoppingCartTotalAmount > 0) {
      this.stripe = Stripe(this.stripeCore.apiKey, {
        stripeAccount: this.stripeAccountId
      });
      const elements = this.stripe.elements();
      this.card = elements.create('card', {
        'style': {
          'base': {
            'width': '100%'
          }
        }
      });
      this.card.mount(this.cardElement.nativeElement);
      this.card.addEventListener('change', ({ error }) => {
        this.cardErrors = error && error.message;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if (this.shoppingCart.shoppingCartTotalAmount > 0) {
      this.card.removeEventListener('change');
      this.card.destroy();
    }
  }

  removeItemFromCart(item: CartItem): void {
    this.store.dispatch(AppActions.removeItemFromShoppingCart({ item: item }));
  }

  async onSubmit(form: Event): Promise<void> {

    if (!this.checkoutForm.invalid) {
      this.isCheckoutActive = true;
      this.store.dispatch(AppActions.beginStripeCheckout());
      this.stripeElementsMessage$.next('Processing payment...');
      this.showPaymentSpinner = true;
      const { token, errorOne } = await this.stripe.createToken(this.card);

      //try
      //{
      const { paymentMethod, errorTwo } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: { token: token.id }
      });

      const functionValues = {
        clientCode: this.cityContext.clientCode,
        properName: `${this.user.userFirstName} ${this.user.userLastName}`,
        clientLongCode: this.cityContext.cityLongCode.toUpperCase(),
        cartItems: this.shoppingCart.shoppingCartItems,
        //convenienceFee: Math.round(this.shoppingCart.shoppingCartTotalConvenienceFees),
        convenienceFee: this.shoppingCart.shoppingCartTotalConvenienceFees,
        paymentMethodId: paymentMethod.id
      };

      console.log("functionValues ", functionValues);

      //const payInvoiceFunction = this.fxns.httpsCallable('csc_payInvoice');
      const payInvoiceFunction = this.fxns.httpsCallable('csc_payAnonInvoice');

      payInvoiceFunction(functionValues).toPromise()
        .then(receipt => {
          this.store.dispatch(AppActions.completeStripeCheckout());
          this.showPaymentSpinner = false;
          this.stripeElementsMessage$.next('Payment successful');
          console.log(receipt);


          //send notification email
          let receiptDate = new Date();
          receipt.receiptPaidAt = receiptDate.toDateString() + " " + receiptDate.toLocaleTimeString();
          this.store.dispatch(PtActions.loadParkingTicketReceipt({ receipt }));
          let toEmail: string = this.checkoutForm.value.receiptEmail;
          let fromEmail: string = this.cityContext.paymentGatewayEmailOptions.emailFromAddress;
          let fromName: string = this.cityContext.paymentGatewayEmailOptions.emailFromName;
          let clientCode: string = this.cityContext.clientCode;
          console.log(toEmail, fromEmail, fromName, clientCode);
          let receiptHtml = this.emailService.generateReceiptHtml(receipt, this.style);
          this.emailService.sendReceiptEmail(toEmail, fromEmail, fromName, "Receipt for parking ticket payment from " + this.cityContext.cityDisplayName, "test payment received", receiptHtml, clientCode);



          setTimeout(() => {
            this.store.dispatch(AppActions.completeStripeCheckout());
            this.cdr.detectChanges();
            this.stripeElementsMessage$.next('');
            //this.router.navigateByUrl('/receipts');
            this.router.navigateByUrl('/receipts-unauthenticated');

            const ubInvoices = functionValues.cartItems.filter(item => item.cartItemSubledger == "UB");
            const txNotices = functionValues.cartItems.filter(item => item.cartItemSubledger == "TX");
            const ptTickets = functionValues.cartItems.filter(item => item.cartItemSubledger == "PT");
            this.store.dispatch(UbActions.updateUtilityBillsAfterSuccessfulPayment({ invoices: ubInvoices, receipts: receipt.receiptCartItems }));
            this.store.dispatch(TxActions.updateTaxNoticesAfterSuccessfulPayment({ invoices: txNotices, receipts: receipt.receiptCartItems }));
            this.store.dispatch(PtActions.updateParkingTicketssAfterSuccessfulPayment({ invoices: ptTickets, receipts: receipt.receiptCartItems }));
            this.store.dispatch(AppActions.clearShoppingCart());
            this.isCheckoutActive = false;
          }, 2000)
        })
        .catch(error => {
          console.log("error:" + error);
          this.showPaymentSpinner = false;
          this.stripeElementsMessage$.next('Unable to process payment.');
        })
    }

    //}
    //catch(error)
    //{
    //console.log("error:" + error);
    //this.showPaymentSpinner = false;
    //this.stripeElementsMessage$.next('Unable to process payment.');
    //}

  }

  /*
  async onSubmit(form: Event): Promise<void> {

    let receipt: Receipt = {
      _id: "j4pq183WiJYDxS6mVs09",
      receiptAmount: 100000,
      receiptCartItems: [{
        cartItemAmountToPay: 46546543,
        cartItemInvoiceIdToPay: "UB-78-TEST",
        cartItemDescription: "SAMPLE",
        cartItemAccountNumber: "78",
        cartItemSubledger: "UB",
        cartItemInvoiceCreatedAt: 1664384605413
    }],
      receiptPaymentIntentId: "eg_pi354366",
      receiptProcessingFeeCost: 4556563,
      receiptConvenienceFeeRevenue: 465365,
      receiptPaidAt: "2022-10-20",
      receiptSubtotal: 653664,
      receiptEmail: this.checkoutForm.value.receiptEmail
  }
  //this.dataService.receipt = receipt;
  this.store.dispatch(PtActions.loadParkingTicketReceipt({receipt}));
  let toEmail: string = this.checkoutForm.value.receiptEmail;
  let fromEmail: string = this.cityContext.paymentGatewayEmailOptions.emailFromAddress;
  let fromName: string = this.cityContext.paymentGatewayEmailOptions.emailFromName;
  let clientCode: string = this.cityContext.clientCode;
  console.log(toEmail,fromEmail,fromName,clientCode);


let receiptHtml = this.emailService.generateReceiptHtml(receipt,this.style);

console.log("receiptHtml",receiptHtml);


  this.emailService.sendReceiptEmail(toEmail,fromEmail,fromName,"Receipt for parking ticket payment",receiptHtml,clientCode);


  const functionValues = {
    clientCode: this.cityContext.clientCode,
    properName: `${this.user.userFirstName} ${this.user.userLastName}`,
    clientLongCode: this.cityContext.cityLongCode.toUpperCase(),
    cartItems: this.shoppingCart.shoppingCartItems,
    //convenienceFee: Math.round(this.shoppingCart.shoppingCartTotalConvenienceFees),
    convenienceFee: this.shoppingCart.shoppingCartTotalConvenienceFees,
    paymentMethodId:"test"
  };

  console.log("functionValues ", functionValues);

    //this.router.navigateByUrl('/parking-tickets-receipts');
    this.router.navigateByUrl('/receipts-unauthenticated');




    const ubInvoices = functionValues.cartItems.filter(item => item.cartItemSubledger == "UB");
    const txNotices = functionValues.cartItems.filter(item => item.cartItemSubledger == "TX");
    const ptTickets = functionValues.cartItems.filter(item => item.cartItemSubledger == "PT");
    this.store.dispatch(UbActions.updateUtilityBillsAfterSuccessfulPayment({invoices: ubInvoices, receipts: receipt.receiptCartItems}));
    this.store.dispatch(TxActions.updateTaxNoticesAfterSuccessfulPayment({invoices: txNotices, receipts: receipt.receiptCartItems}));
    this.store.dispatch(PtActions.updateParkingTicketssAfterSuccessfulPayment({invoices: ptTickets, receipts: receipt.receiptCartItems}));
    this.store.dispatch(AppActions.clearShoppingCart());
    this.isCheckoutActive = false;
    */

  /*
  this.isCheckoutActive = true;
  this.store.dispatch(AppActions.beginStripeCheckout());
  this.stripeElementsMessage$.next('Processing payment...');
  this.showPaymentSpinner = true;
  const { token, errorOne } = await this.stripe.createToken(this.card);

  const { paymentMethod, errorTwo } = await this.stripe.createPaymentMethod({
    type: 'card',
    card: { token: token.id }
  });

  const functionValues = {
    clientCode: this.cityContext.clientCode,
    properName: `${this.user.userFirstName} ${this.user.userLastName}`,
    clientLongCode: this.cityContext.cityLongCode.toUpperCase(),
    cartItems: this.shoppingCart.shoppingCartItems,
    //convenienceFee: Math.round(this.shoppingCart.shoppingCartTotalConvenienceFees),
    convenienceFee: this.shoppingCart.shoppingCartTotalConvenienceFees,
    paymentMethodId: paymentMethod.id
  };

  console.log("functionValues ", functionValues);

  const payInvoiceFunction = this.fxns.httpsCallable('csc_payInvoice');

  payInvoiceFunction(functionValues).toPromise()
    .then(receipt => {
      this.store.dispatch(AppActions.completeStripeCheckout());
      this.showPaymentSpinner = false;
      this.stripeElementsMessage$.next('Payment successful');
      console.log(receipt);
      setTimeout(() => {
      this.store.dispatch(AppActions.completeStripeCheckout());
        this.cdr.detectChanges();
        this.stripeElementsMessage$.next('');
        this.router.navigateByUrl('/receipts');
        const ubInvoices = functionValues.cartItems.filter(item => item.cartItemSubledger == "UB");
        const txNotices = functionValues.cartItems.filter(item => item.cartItemSubledger == "TX");
        const ptTickets = functionValues.cartItems.filter(item => item.cartItemSubledger == "PT");
        this.store.dispatch(UbActions.updateUtilityBillsAfterSuccessfulPayment({invoices: ubInvoices, receipts: receipt.receiptCartItems}));
        this.store.dispatch(TxActions.updateTaxNoticesAfterSuccessfulPayment({invoices: txNotices, receipts: receipt.receiptCartItems}));
        this.store.dispatch(PtActions.updateParkingTicketssAfterSuccessfulPayment({invoices: ptTickets, receipts: receipt.receiptCartItems}));
        this.store.dispatch(AppActions.clearShoppingCart());
        this.isCheckoutActive = false;
      }, 2000)
    })
    .catch(error => {
      console.log("error:" + error);
      this.showPaymentSpinner = false;
      this.stripeElementsMessage$.next('Unable to process payment.');
    })

  
}
      */
}
