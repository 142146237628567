import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { appReducer, AppEffects, metaReducers } from './global/state';

import { SharedModule } from './shared/shared.module';

import { Error404Component } from './global/components/error404/error404.component';
import { SelectCityComponent } from './global/components/select-city/select-city.component';
import { LoginComponent } from './global/components/login/login.component';
import { ShoppingCartComponent } from './global/components/shopping-cart/shopping-cart.component';
import { AddAccountComponent } from './global/components/add-account/add-account.component';
import { Error403Component } from './global/components/error403/error403.component';
import { ReceiptsComponent } from './global/components/receipts/receipts.component';
import { EmptyCartComponent } from './global/components/shopping-cart/empty-cart/empty-cart.component';
import { PrintReceiptComponent } from './global/components/receipts/print-receipt/print-receipt.component';
import { UnauthenticatedModule } from './unauthenticated/unauthenticated.module';

@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    SelectCityComponent,
    LoginComponent,
    ShoppingCartComponent,
    AddAccountComponent,
    Error403Component,
    ReceiptsComponent,
    EmptyCartComponent,
    PrintReceiptComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    UnauthenticatedModule,
    AngularFireModule.initializeApp(environment.firebase),

    // NGRX store
    StoreModule.forRoot({ app: appReducer }, {metaReducers}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AppEffects])
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [EmptyCartComponent, AddAccountComponent]
})

export class AppModule { }
