import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Receipt } from "src/app/global/state/models/models";

@Injectable({
    providedIn: 'root'
})

export class EmailService {
    constructor(
        private db: AngularFirestore,
        private httpClient: HttpClient
    ) { }

    generateReceiptHtml(receipt: Receipt, styles: any): string {
        let tdStyle = "font-family: Arial, Helvetica, sans-serif;border: 1px solid #ddd; padding: 8px;text-align: right";
        let receiptHtml = '<table width="67%; border: 1px solid #ddd;font-family: Arial, Helvetica, sans-serif;" cellpadding="0" cellspacing="0">';
        receiptHtml = receiptHtml + '<thead>';
        receiptHtml = receiptHtml + "<tr>";
        receiptHtml = receiptHtml + '<th style="font-family: Arial, Helvetica, sans-serif;border: 1px solid #ddd; padding: 8px; background-color: ' + styles.background["background-color"] + '; color: ' + styles.text["color"] + '; alignment: center; bold: true">Description</th>';
        receiptHtml = receiptHtml + '<th style="font-family: Arial, Helvetica, sans-serif;border: 1px solid #ddd; padding: 8px; background-color: ' + styles.background["background-color"] + '; color: ' + styles.text["color"] + '; alignment: center; bold: true">Amount</th>';
        receiptHtml = receiptHtml + "</tr>";
        receiptHtml = receiptHtml + "</thead>";
        receiptHtml = receiptHtml + "<tbody>";

        for (let i = 0; i < receipt.receiptCartItems.length; i++) {
            receiptHtml = receiptHtml + '<tr><td style="border: 1px solid #ddd; padding: 8px;text-align: center;font-family: Arial, Helvetica, sans-serif;">' + receipt.receiptCartItems[i].cartItemInvoiceIdToPay + '</td><td style="' + tdStyle + '">' + this.formatNumberToCurrency(receipt.receiptCartItems[i].cartItemAmountToPay) + '</td></tr>';
        }

        receiptHtml = receiptHtml + "<tr>";
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">Processing fee cost:</td>';
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">' + this.formatNumberToCurrency(receipt.receiptProcessingFeeCost) + '</td>';
        receiptHtml = receiptHtml + "</td>";
        receiptHtml = receiptHtml + "</tr>";
        receiptHtml = receiptHtml + "<tr>";
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">Receipt Total:</td>';
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">' + this.formatNumberToCurrency(receipt.receiptAmount) + '</td>';
        receiptHtml = receiptHtml + "</tr>";
        receiptHtml = receiptHtml + "<tr>";
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">&nbsp;</td>';
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">&nbsp;</td>';
        receiptHtml = receiptHtml + "</tr>";
        receiptHtml = receiptHtml + "<tr>";
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">Receipt ID:</td>';
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">' + receipt._id + '</td>';
        receiptHtml = receiptHtml + "</tr>";
        receiptHtml = receiptHtml + "<tr>";
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">Payment Date:</td>';
        receiptHtml = receiptHtml + '<td style="' + tdStyle + '">' + receipt.receiptPaidAt + '</td>';
        receiptHtml = receiptHtml + "</tr>";

        receiptHtml = receiptHtml + "</tbody>";
        receiptHtml = receiptHtml + "</table>";

        return receiptHtml;

    }

    private formatTimestampToUserFriendlyDate(timestamp: any) {
        console.log("timestamp",timestamp);
        let date = new Date(timestamp * 1000);
        return date.toDateString() + ", " + date.toLocaleTimeString();
    }

    private formatNumberToCurrency(numberToFormat: any) {
        let formattedNumber = (numberToFormat / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        return formattedNumber;
    }

    sendReceiptEmail(toEmail: string, fromEmail: string, fromName: string, subject: string, text: string, receiptHtml: string, clientCode: string) {
        console.log("sending receipt email");



        let emailRequest = {
            "toEmail": toEmail,
            "toName": "",
            "fromEmail": fromEmail,
            "fromName": fromName,
            "cc": "",
            "ccName": "",
            "replyTo": "",
            "subject": subject,
            "text": text,
            "html": receiptHtml
        }
      

        /*
        let emailRequest = {
                "toEmail" : "gwagnor@amaistech.com",
                "fromEmail" : "amaisville@amaistech.com",
                "subject" : "receipt for parking ticket payment 7",
                "text" : "this is a receipt email 7",
                "html" : "<p>test from amaisville 7</p>"
        }
        */


        
        //let url = "https://us-central1-citreq-dev.cloudfunctions.net/send_email?clientCode=" + clientCode;
        //let url = "https://us-central1-citreq-dev.cloudfunctions.net/receiptEmailSend"
        let url = "https://us-central1-amais-suite-staging.cloudfunctions.net/receiptEmailSend"

        //let headers = new HttpHeaders({
            //'Content-Type': 'application/json',
        //});


        this.httpClient.post<any>(url, emailRequest).subscribe(response => { 

            console.log(response)
        },
            error => {
                console.log(error.status);
            }
        )


    }
}

