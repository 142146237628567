import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as AppSelectors from '../../state/app.selectors';
import * as AppActions from '../../state/app.actions';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { CscClient, CscStyles, FirebaseUser } from '../../state/models/models';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../services/login.service';
import { ContextService } from '../../services/context.service';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup; // form for logging in
  passwordResetForm: FormGroup; // form for resetting a password
  newUserForm: FormGroup; // form for logging in
  isLoginError: boolean = false;
  cityContext$: Observable<CscClient>; // fb document that stores styles based on client longname
  user$: Observable<FirebaseUser>;
  subscription: Subscription;
  logoImageUrl: Observable<string>;
  showSpinner: boolean = true;
  styles$: Observable<CscStyles>;
  subscriptions: Subscription[] = [];
  newUserSpinner;

  cityList;// = ["amaisville", "townofboone", "cranbrook", "villageoflumby", "townofgolden", "townofcomox", "williamslake","villageofqueencharlotte","townoflakecowichan"];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private storage: AngularFireStorage,
    private router: Router,
    private snackbar: MatSnackBar,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private context: ContextService
  ) {
    this.store.dispatch(AppActions.clearUser());
  }

  ngOnInit(): void {
    this.initializeApp();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async initializeApp() {
    this.cityList = await this.getCityList();
    console.log(this.cityList);
    this.initializeApplicationLogin();
  }

  initializeApplicationLogin(): void {
    let pathVar = this.activatedRoute.snapshot.paramMap.get('id');
    let cityLongCode;

    localStorage.getItem('city') ? cityLongCode = localStorage.getItem('city') : cityLongCode = pathVar;

    if (this.checkIfCityLongCodeIsValid(cityLongCode)) {
      this.createForms();
      this.store.dispatch(AppActions.loadCityContext({ cityLongCode }));
      this.cityContext$ = this.store.select(AppSelectors.getCityContext);
      this.user$ = this.store.select(AppSelectors.getFirebaseUser);
      const subscription1 = this.store.select(AppSelectors.getLogoRef)
        .subscribe(ref => {
          ref ? this.logoImageUrl = this.storage.ref(ref).getDownloadURL() : {};
          this.showSpinner = false;
        })
      this.subscriptions.push(subscription1);
      this.styles$ = this.store.select(AppSelectors.getStyles);
    } else {
      this.loginService.logout();
      this.router.navigateByUrl('/select-city');
    }
  }

  async getCityList() {
    return this.context.loadClientCities()
    // let list$ = this.context.loadClientCities()
      .pipe(
        take(1),
        map(cities => {
          let cityList: string[] = [];
          cities.forEach(city => {
            if (city.cityLongCode) {
              cityList.push(city.cityLongCode);
            }
          })
          return cityList;
        })
      )
      .toPromise();
      // .subscribe(list => this.cityList = list);
  }

  // checks to ensure that long code is valid
  checkIfCityLongCodeIsValid(cityLongCode: string): boolean {
    return this.cityList.includes(cityLongCode.toLowerCase());
  }

  createForms() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.passwordResetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.newUserForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', Validators.required]
    });
  }

  /**
   * 1- get the email and password from the form
   * 2- call the auth service to log in to firebase
   * 3- if successful, check that the user 'belongs' to the city by checking FirebaseUser:userCityCode === CscClient.cityLongCode
   * 4- if so, proceed normally
   * 5- if not, then log out, clear state
   */

  async login() {
    // 1- get the email and password from the form
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;

    // 2- call the auth service to log in to firebase 
    try {
      await this.loginService.loginIntoFirebase(email, password);
      const userCityCodeFromToken = await this.loginService.getUserCityCode();
      console.log("userCityCode", userCityCodeFromToken);
      this.store.dispatch(AppActions.loadFirebaseUser());
      let cityContext, user: FirebaseUser;
      this.newUserSpinner = true;

      // 3- check that the user 'belongs' to the city by checking FirebaseUser:userCityCode === CscClient.cityLongCode
      setTimeout(() => {
        let citySubscription = this.cityContext$.subscribe(context => cityContext = context);
        let userSubscription = this.store.select(AppSelectors.getFirebaseUser).subscribe(fbUser => user = fbUser);
        this.subscriptions.push(citySubscription);
        this.subscriptions.push(userSubscription);
        console.log('user', user.userCityCode.toUpperCase());
        console.log('token', userCityCodeFromToken.toUpperCase());
        console.log('city', cityContext.cityLongCode.toUpperCase());
      }, 1000)

      setTimeout(() => {
        if (userCityCodeFromToken.toUpperCase() === cityContext.cityLongCode.toUpperCase()) {
          this.router.navigateByUrl('/home');
        } else {
          this.loginService.logout();
          this.store.dispatch(AppActions.clearState());
          this.router.navigateByUrl(`/unknown-user`);
        }
      }, 2000)
    } catch (error) {
      this.isLoginError = true;
      this.loginForm.reset();
      setTimeout(() => {
        this.isLoginError = false;
      }, 2000)
    }
  }

  async forgotPassword() {
    try {
      this.loginService.forgotPassword(this.passwordResetForm.value.email);
      this.snackbar.open("A password reset email has been sent", "", { duration: 2000, verticalPosition: 'top' });
    } catch (error) {
      this.snackbar.open("Something went wrong!  Please try again", "", { duration: 2000, verticalPosition: 'top' });
    }
  }

  async registerNewUser() {
    this.newUserSpinner = true;

    const newUser = {
      userFirstName: this.newUserForm.value.firstName,
      userLastName: this.newUserForm.value.lastName,
      userEmail: this.newUserForm.value.email,
      userPassword: this.newUserForm.value.password,
      userCityCode: (this.activatedRoute.snapshot.params.id).toUpperCase()
    };

    try {
      await this.loginService.createNewUser(newUser);
      this.newUserSpinner = false;
      this.snackbar.open("Your user has been created. Please log in.", "", { duration: 2000, verticalPosition: 'top' });
    } catch (error) {
      this.snackbar.open(error, "", { duration: 2000, verticalPosition: 'top' });
      this.newUserSpinner = false;
      this.newUserForm.reset();
    }
  }

}
