<ng-container *ngIf="(availableWorkflowTasks$|async)?.length > 0">

    <div class="h1">TASK CONTAINER COMPONENT</div>

    <ng-container *ngFor="let task of (availableWorkflowTasks$|async)">

        <ng-container *ngIf="task.type === 'Approval'">
            <app-approval-task></app-approval-task>
        </ng-container>

        <ng-container *ngIf="task.type === 'Confirmation'">
            <app-confirmation-task></app-confirmation-task>
        </ng-container>

        <ng-container *ngIf="task.type === 'Form'">
            <app-form-task [formTemplate]="task.formTemplateData" [instanceId]="(activeInstance$|async)?._id"
                [taskId]="task.id" [taskDescription]="task.description"></app-form-task>
        </ng-container>

        <ng-container *ngIf="task.type === 'Fee'">
            <app-payment-task></app-payment-task>
        </ng-container>

        <ng-container *ngIf="task.type === 'FileUpload'">
            <app-upload-task></app-upload-task>
        </ng-container>
    </ng-container>
</ng-container>


<ng-container *ngIf="(availableWorkflowTasks$|async)?.length === 0">
    <h1>No tasks loaded</h1>
</ng-container>