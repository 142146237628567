/**
 * Workflow Service is used for the following:
 * - retrieving a workflow template
 * - instantiating a new workflow instance from a template 
 * - retrieving a workflow instance
 * - updating a task within a workflow instance
 */

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { defaultStateFn, Store } from '@ngrx/store';
import * as AppSelectors from '../../global/state/app.selectors';
import { AngularFireFunctions } from '@angular/fire/functions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WorkflowTemplate } from '../state/models/workflow/workflowTemplate';
import { WorkflowInstance } from '../state/models/workflow/workflowInstance';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppActions } from '../state';
import { TaskTemplate } from '../state/models/workflow/models';
import { Workflow } from '../state/app.reducers';

export interface WorkflowTemplateDataReturn {
    success: boolean,
    error: any,
    data: { template: WorkflowTemplate }
}

export interface WorkflowInstanceDataReturn {
    success: boolean,
    error: any,
    data: {
        instance?: WorkflowInstance,
        updatedInstance?: WorkflowInstance,
    }
}

@Injectable({
    providedIn: 'root'
})

export class WorkflowService {

    workflowUrl: string = 'https://us-central1-citreq-dev.cloudfunctions.net/workflowengine';

    private get httpHeaders(): HttpHeaders {
        const token = `Bearer DIVESHFAKEAMAISTOKEN`;
        return new HttpHeaders({
            'Authorization': token
        });
    }

    constructor(
        private http: HttpClient,
        private storage: AngularFireStorage,
        private auth: AngularFireAuth
    ) { }

    /**
     * 
     * @param templateId string representing the id of the workflow template
     */
    getWorkflowTemplate(templateId: string, clientCode: string): Observable<WorkflowTemplate> {
        const url = `${this.workflowUrl}/workflow/template/${templateId}?clientCode=${clientCode}`;
        return this.http.get<WorkflowTemplateDataReturn>(
            url,
            { headers: this.httpHeaders }
        )
            .pipe(
                map(data => {
                    return data.data.template;
                })
            );
    }

    /**
     * 
     * @param instanceId 
     * @param clientCode 
     * @returns 
     */
    getWorkflowInstance(instanceId: string, clientCode: string): Observable<Workflow> {
        const url = `${this.workflowUrl}/workflow/instance/${instanceId}?clientCode=${clientCode}`;
        return (this.http.get<WorkflowInstanceDataReturn>(
            url,
            { headers: this.httpHeaders }
        )
            .pipe(
                // take(1),
                map((data: WorkflowInstanceDataReturn) => {
                    const activeInstance = data.data.instance;
                    const availableTasks = this.getAvailableTasksInWorkflowInstanceArray(activeInstance)
                    const workflow: Workflow = {
                        activeInstance: activeInstance,
                        availableTasks: availableTasks
                    }
                    return workflow;
                }),
            ))
    }

    createWorkflowFromTemplateId(clientCode: string, templateId: string): Observable<Workflow> {

        console.log("create workflow instance from template");

        const url = `${this.workflowUrl}/workflow/instance?clientCode=${clientCode}&templateId=${templateId}`;
        const data$ = this.http.post<WorkflowInstanceDataReturn>(
            url,
            {},
            { headers: this.httpHeaders }
        ).pipe(
            map((data: WorkflowInstanceDataReturn) => {
                const activeInstance = data.data.instance;
                const availableTasks = this.getAvailableTasksInWorkflowInstanceArray(activeInstance)
                const workflow: Workflow = {
                    activeInstance: activeInstance,
                    availableTasks: availableTasks
                }
                return workflow;
            })
        )
        return data$;

    }

    updateWorkflowTask() { }

    /**
     * 
     * @param fileName 
     * @param imageUrl 
     */
    async completeFileUpload(fileName: string, imageUrl: string, clientCode: string, module: string, instanceId: string) {
        const imageRef = this.storage.ref(`${clientCode}/CSC/${instanceId}/${fileName}`);
        try {
            await imageRef.putString(imageUrl, 'data_url')
        } catch (error) {
            console.log(error);
        }
    }

    onSaveFormAction() { }

    onSubmitFormAction(clientCode: string, instanceId: string, taskId: string, action: string, formSubmissionData: any): Observable<Workflow> {
        const url = `${this.workflowUrl}/workflow/instance/${instanceId}/task/${taskId}/${action}?clientCode=${clientCode}`;
        const formSubmissionBody = JSON.stringify(formSubmissionData);
        const data$ = this.http
            .put<WorkflowInstanceDataReturn>(
                url,
                {
                    formSubmissionData: formSubmissionBody,
                },
                { headers: this.httpHeaders }
            )
            .pipe(
                map((data: WorkflowInstanceDataReturn) => {
                    const activeInstance = data.data.updatedInstance;
                    const availableTasks = this.getAvailableTasksInWorkflowInstanceArray(activeInstance)
                    const workflow: Workflow = {
                        activeInstance: activeInstance,
                        availableTasks: availableTasks
                    }
                    return workflow;
                })
            );
        return data$;
    }

    onSelectAlgoliaItem(clientCode: string, instanceId: string, taskId: string, action: string, submissionData: any): Observable<Workflow> {
        const url = `${this.workflowUrl}/workflow/instance/${instanceId}/task/${taskId}/${action}?clientCode=${clientCode}`;
        const formSubmissionBody = JSON.stringify(submissionData);
        console.log(formSubmissionBody);
        const data$ = this.http
            .put<WorkflowInstanceDataReturn>(
                url,
                {
                    submissionData: formSubmissionBody,
                },
                { headers: this.httpHeaders }
            )
            .pipe(
                map((data: WorkflowInstanceDataReturn) => {
                    const activeInstance = data.data.updatedInstance;
                    const availableTasks = this.getAvailableTasksInWorkflowInstanceArray(activeInstance)
                    const workflow: Workflow = {
                        activeInstance: activeInstance,
                        availableTasks: availableTasks
                    }
                    return workflow;
                })
            );
        return data$;
    }

    getAvailableTasksInWorkflowInstanceArray(instance: WorkflowInstance, role: string = "citizen"): TaskTemplate[] {
        const tasks: any = [];
        Object.keys(instance.tasks).forEach(taskId => tasks.push({ id: taskId, ...instance.tasks[taskId] }));
        const taskIds = tasks
            .filter(
                (task: any) => task.prerequisites.length == 0 ? true :
                    (task.prerequisites).reduce((acc: any, prereq: string | number) => acc && instance.tasks[prereq].isComplete, true)
            )
            .filter(
                (task: any) => !task.isComplete
            )
            .filter(
                (task: any) => task.rolesToCompleteTask.indexOf(role) != -1
            )
            .map(
                (task: any) => task.id
            );
        // return taskIds;

        let availableTasks: TaskTemplate[] = [];
        taskIds.forEach(id => availableTasks.push(instance.tasks[id]));
        return availableTasks;
    }
}

/**
 *
 */