import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppActions, AppSelectors } from 'src/app/global/state';
import { CartItem, Citizen } from 'src/app/global/state/models/models';
import { FeeTaskInstance } from 'src/app/global/state/models/workflow/feeTaskInstance';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-payment-task',
  templateUrl: './payment-task.component.html',
  styleUrls: ['./payment-task.component.scss']
})
export class PaymentTaskComponent implements OnInit {


  @Input() task: FeeTaskInstance
  @Input() instanceId: string
  citizen: Citizen;

  constructor(
    private store: Store,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.task, "task");
    console.log(this.instanceId, "instanceId");
    this.store.select(AppSelectors.getCitizen).pipe(take(1)).subscribe(citizen => this.citizen = citizen);
  }

  addToCartAndCheckout() {
    const cartItem: CartItem = {
      cartItemAmountToPay: this.task.amountDue,
      cartItemInvoiceIdToPay: `${this.task.name} - ${DateTime.now().toISODate()}`,
      cartItemDescription: `${this.task.name} - ${DateTime.now().toISODate()}`,
      cartItemAccountNumber: this.citizen._id,
      cartItemSubledger: 'CSC',
      cartItemInvoiceCreatedAt: DateTime.now().toISODate()
    }

    this.store.dispatch(AppActions.addItemToShoppingCart({item: cartItem, subledger: 'AL'}));
    this.router.navigateByUrl('/shopping-cart');
  }

}
