import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { ContextService } from '../../services/context.service';
import * as AppActions from '../../state/app.actions';
import { ClientCity } from '../../state/models/models';

@Component({
  selector: 'app-select-city',
  templateUrl: './select-city.component.html',
  styleUrls: ['./select-city.component.scss']
})
export class SelectCityComponent implements OnInit {

  cityList: ClientCity[];

  citySelectForm: FormGroup;
  showSpinner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private store: Store,
    private context: ContextService
  ) {
    localStorage.removeItem('city');
    this.store.dispatch(AppActions.clearState());
  }

  ngOnInit(): void {
    this.citySelectForm = this.fb.group({
      cityLongCode: ['', Validators.required]
    });
    let list$ = this.context.loadClientCities()
      .pipe(
        map(cities => {
          let cityList: ClientCity[] = [];
          cities.forEach(city => {
            if (city.cityLongCode) {
              cityList.push({
                cityDisplayName: city.cityDisplayName,
                cityLongCode: city.cityLongCode,
                isActive: true
              });
            }
          })
          return cityList;
        })
      )
      .subscribe(list => this.cityList = list);
  }

  onSubmit(): void {
    // get the long code from the form
    const cityLongCode = this.citySelectForm.value.cityLongCode;

    // find the object in cityList array
    const cityDetails = this.cityList.find(city => cityLongCode == city.cityLongCode);

    // check if city is active, if so, then redirect.  if not, then show modal
    if (cityDetails.isActive) {
      this.showSpinner = true;
      this.store.dispatch(AppActions.loadCityContext({ cityLongCode }));
      setTimeout(() => {
        this.router.navigate(['/login', this.citySelectForm.value.cityLongCode]);
      }, 1000)
    } else {
      // this.dialog.open(CityUnavailableComponent, {width: '600px', height: '400px' });
    }
  }
}
