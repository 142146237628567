<ng-container *ngIf="(cityInfo$|async)?.cityLongCode != ''">

    <mat-toolbar fxLayout="row" [ngStyle]="(styles$|async)?.button">
        <span class="home" [routerLink]="['/login']" fxFlex>{{ (cityInfo$|async)?.cityDisplayName }}</span>
        <!--<button mat-button fxHide.xs [routerLink]="['/parking-tickets-receipts']">
            <mat-icon class="mr">search</mat-icon>&nbsp;Search Receipts
        </button>-->
        <ng-container *ngIf="(cityInfo$|async)?.cityProductAccess?.CART">
            <button mat-button fxHide.xs (click)="showShoppingCart()">
                <mat-icon class="mr" [matBadge]="(shoppingCart$|async)?.shoppingCartItemsCount"
                    matBadgePosition="before below" matBadgeColor="accent">
                    shopping_cart
                </mat-icon>
                &nbsp;Cart
            </button>
        </ng-container>
    </mat-toolbar>
    <mat-sidenav-container class="example-container">
        <mat-sidenav-content class="main-container">
            <div>

                <div class="app-page">
                    <div class="title">
                        Parking Tickets
                        <hr>
                    </div>
                    <div class="row" style="width:100%">
                        <div class="col-sm-4">
                            <mat-card class="csc-card">
                                <mat-card-header>
                                    <mat-card-title style="text-align: center;">Search for parking tickets
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content style="text-align: center;">
                                    <form [formGroup]="searchParkingTicketsForm">
                                        <div class="form-fields">
                                            <mat-form-field style="width:300px;">
                                                <mat-label>Enter parking ticket number or license plate</mat-label>
                                                <input matInput formControlName="ticketNumberLicensePlate" autofocus>
                                            </mat-form-field>
                                            <div>

                                                <label>
                                                    <input type="radio" value="licensePlate" formControlName="searchBy">
                                                    <span> License plate</span>
                                                </label>
                                                &nbsp;&nbsp;
                                                <label>
                                                    <input type="radio" value="ticketNumber" formControlName="searchBy">
                                                    <span> Ticket number</span>
                                                </label>
                                            </div>
                                            <div>
                                                <!--<mat-form-field style="width:300px;">

                                                    <select matNativeControl required formControlName="paymentStatus">
                                                        <option value="Y">Outstanding</option>
                                                        <option value="N">Paid</option>
                                                        <option value="A">All</option>
                                                    </select>
                                                </mat-form-field>-->
                                            </div>

                                            <button mat-raised-button [ngStyle]="(cityInfo$ | async)?.styles.button"
                                                type="submit" (click)="searchForTickets()"
                                                [disabled]="searchParkingTicketsForm.invalid">
                                                Search
                                            </button>
                                            <ng-container *ngIf="error">
                                                <hr>
                                                <p class="error-message">
                                                    {{ error$ | async }}
                                                </p>
                                            </ng-container>
                                        </div>
                                    </form>
                                </mat-card-content>
                            </mat-card>

                        </div>
                        <div class="col-sm-8">
                            <div style="text-align: center;" *ngIf="!messageObject.isError && (searchForInvoices$ | async)?.length == 0">
                                <div class="h3">{{ messageObject.message }}</div>
                            </div>
                            <div style="text-align: center;" *ngIf="(searchForInvoices$ | async)?.length > 0">
                                <div class="h3">Your Tickets</div>
                            

                            <div class="csc-account-detail-card" *ngFor="let invoice of (searchForInvoices$ | async)">
                                <div class="csc-account-list-card">
                                    <div class="csc-card-header">
                                        <mat-icon class="app-icon" [ngStyle]="(styles$|async)?.button">assignment
                                        </mat-icon>
                                    </div>
                                    <div class="csc-card-body csc-card-center-text">
                                        <div style="padding: 2px;"><b>Ticket #</b>
                                            {{invoice.invoiceData.invoiceAccountNumber}}</div>
                                        <div style="padding: 2px;"><b>License plate #</b>
                                            {{invoice.invoiceDescription}}
                                        </div>
                                        <div style="padding: 2px;"><b>Amount:</b> {{ (invoice.invoiceAmountDue/100)
                                            |
                                            currency}}</div>
                                        <div *ngIf="((invoice.invoiceAmountDue -
                                        invoice.invoiceAmountPaid) > 0)" class="icon-with-text add-account-link"
                                            (click)="addItemToShoppingCart(invoice)">
                                            <mat-icon>add_shopping_cart</mat-icon>
                                            <span>
                                                &nbsp;Add outstanding amount {{(invoice.invoiceAmountDue -
                                                invoice.invoiceAmountPaid) / 100 |
                                                currency}} to shopping cart
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <mat-toolbar class="footer"></mat-toolbar>
</ng-container>