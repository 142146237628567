import { AfterContentChecked, AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { WorkflowService } from 'src/app/global/services/workflow.service';
import { AppActions, AppSelectors } from 'src/app/global/state';
import {WorkflowTemplate} from 'src/app/global/state/models/workflow/workflowTemplate';

@Component({
  selector: 'app-form-task',
  templateUrl: './form-task.component.html',
  styleUrls: ['./form-task.component.scss'],
})
export class FormTaskComponent implements OnInit {

  @Input() taskDescription;
  @Input() formTemplate;
  @Input() instanceId: string;
  @Input() taskId: string;
  renderFormTemplate;

  formData: any;

  constructor(
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.renderFormTemplate = JSON.parse(this.formTemplate);
    console.log({ clientCode: 'AMAIS', instanceId: this.instanceId, taskId: this.taskId, action: "formSubmitted", formSubmissionData: this.formData });
  }

  onFormChange(e) {
    if (e.data) {
      this.formData = e.data;
    }
  }

  onSaveForm() {
    try {
      this.store.dispatch(AppActions.formTaskSubmissionInitiate({ clientCode: 'AMAIS', instanceId: this.instanceId, taskId: this.taskId, action: "formSubmitted", formSubmissionData: this.formData }))
    } catch (error) {
      console.log(error);
    }
  }

}
