import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PtState } from './pt.reducer';


const getPtFeatureState = createFeatureSelector<PtState>('ptState');

/**
 * parking ticket selectors
 */

export const getSearchInvoices = createSelector(
   getPtFeatureState,
   state => state.ptSearchInvoices
);

export const getReceipt = createSelector(
   getPtFeatureState,
   state => state.ptReceipt
)

export const getReceipts = createSelector(
   getPtFeatureState,
   state => state.ptReceipts
)