<ng-container *ngIf="!(logoImageUrl | async)">
    <div class="body-container">
        <app-spinner></app-spinner>
    </div>
</ng-container>

<ng-container *ngIf="logoImageUrl | async">
    <div class="body-container">
        <div class="main-container">
            <div class="logo-box">
                <img class="logo" [src]='logoImageUrl | async'>
            </div>
            <div class="login-box">
                <mat-tab-group>
                    <mat-tab label="Log In">
                        <!-- start login form -->
                        <div class="text-center">
                            <h4 *ngIf="!isLoginError" class="intro-text">Welcome Back!</h4>
                            <h4 *ngIf="isLoginError" class="intro-text danger">Invalid username or password</h4>
                        </div>

                        <form [formGroup]="loginForm">

                            <div class="form-fields">
                                <mat-form-field>
                                    <mat-label>Enter your email</mat-label>
                                    <input matInput type="email" formControlName="email">
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>Enter your password</mat-label>
                                    <input type="password" matInput formControlName="password">
                                </mat-form-field>

                                <div *ngIf="newUserSpinner" fxLayout="column" fxLayoutAlign="center center">
                                    <app-spinner fxFlex></app-spinner>
                                </div>

                                <button mat-raised-button [ngStyle]="(styles$|async)?.button" type="submit"
                                    (click)="login()">Login</button>
                                <hr>
                            </div>

                        </form>

                        <!-- end login form -->
                    </mat-tab>

                    <mat-tab label="Forgot Password?">
                        <!-- start forgot form -->
                        <div class="text-center">
                            <h4 class="intro-text">Enter your email below to receive a password reset email
                            </h4>
                        </div>

                        <form [formGroup]="passwordResetForm">
                            <div class="form-fields">
                                <mat-form-field>
                                    <mat-label>Enter your email</mat-label>
                                    <input matInput type="email" formControlName="email">
                                </mat-form-field>

                                <button mat-raised-button [ngStyle]="(styles$|async)?.button" type="submit"
                                    (click)="forgotPassword()" [disabled]="passwordResetForm.invalid">Reset</button>
                                <hr>
                            </div>

                        </form>

                        <!-- end forgot form -->
                    </mat-tab>

                    <mat-tab label="Register">
                        <!-- start newuser form -->
                        <div class="text-center">
                            <h4 *ngIf="!isLoginError" class="intro-text">Set up an account here</h4>
                            <h4 *ngIf="isLoginError" class="intro-text danger">Invalid username or password
                            </h4>
                        </div>

                        <form [formGroup]="newUserForm">
                            <div class="form-fields">
                                <mat-form-field>
                                    <mat-label>Enter your first name...</mat-label>
                                    <input matInput formControlName="firstName">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Enter your last name...</mat-label>
                                    <input matInput formControlName="lastName">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Enter Email Address...</mat-label>
                                    <input matInput type="email" formControlName="email">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Password</mat-label>
                                    <input matInput type="password" formControlName="password">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Password</mat-label>
                                    <input matInput type="password" formControlName="passwordConfirm">
                                </mat-form-field>

                                <div>
                                    <div class="custom-control custom-checkbox small">
                                        <div *ngIf="(newUserForm.value.password != newUserForm.value.passwordConfirm)"
                                            class="form-check">
                                            Passwords must match!
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="newUserSpinner" fxLayout="column" fxLayoutAlign="center center">
                                    <app-spinner fxFlex></app-spinner>
                                </div>
                                <button mat-raised-button [ngStyle]="(styles$|async)?.button" type="submit"
                                    (click)="registerNewUser()"
                                    [disabled]="newUserForm.invalid || (newUserForm.value.password != newUserForm.value.passwordConfirm)">
                                    Create an account
                                </button>
                                <hr>
                            </div>

                        </form>

                        <!-- end newuser form -->
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="chang-city">
                Do you want to pay a parking ticket? Click <a [routerLink]="['/parking-tickets-unauth']">here</a>
            </div>
            <div class="change-city">
                Want to log in to a different city? Click <a [routerLink]="['/select-city']">here</a>
            </div>            
        </div>
    </div>
</ng-container>