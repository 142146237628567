<div class="h6 p-1">Start entering an address below to see available options</div>

<!-- <ais-instantsearch [config]="algoliaConfig">
    <ais-search-box placeholder="Start typing to search..." (change)="showResults = true" (reset)="showResults = false" autofocus>
    </ais-search-box>
    <ais-hits>
        <ng-template let-hits="hits">
            <div *ngIf="showResults">
                <div *ngFor="let hit of hits">
                    <button (click)="onSelectItem(hit)" mat-button>
                        {{hit.address}}
                    </button>
                </div>
            </div>
        </ng-template>
    </ais-hits>
</ais-instantsearch> -->