<ng-container *ngIf="(cityInfo$|async)?.cityLongCode != ''">

    <mat-toolbar fxLayout="row" [ngStyle]="(styles$|async)?.button">
        <span class="home" [routerLink]="['/login']" fxFlex>{{ (cityInfo$|async)?.cityDisplayName }}</span>
        <button mat-button fxHide.xs [routerLink]="['/parking-tickets-unauth']">
            <mat-icon class="mr">search</mat-icon>&nbsp;Search Tickets
        </button>
        <!--<button mat-button fxHide.xs [routerLink]="['/parking-tickets-receipts']">
            <mat-icon class="mr">search</mat-icon>&nbsp;Search Receipts
        </button>-->
    </mat-toolbar>
    <mat-sidenav-container class="example-container">
        <mat-sidenav-content class="main-container">
            <div>

                <div class="app-page">

                    <div class="title">
                        Receipts
                        <hr>
                    </div>

                    <ng-container *ngIf="(receipts$ | async)?.length == 0">
                        <div class="h2">You don't have any receipts to view.
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedReceipt$">
                        <div class="csc-card-row">

                            <div class="csc-account-detail-card">
                                <div class="csc-card-header">
                                    Your Receipt Details
                                    <ng-container *ngIf="selectedReceipt$">
                                        <span> {{ (selectedReceipt$|async)?.receiptAmount /100 | currency}}</span>
                                    </ng-container>
                                </div>

                                <ng-container *ngIf="!selectedReceipt$">
                                    <div class="csc-card-body csc-card-center-text">
                                        Please select receipt on the left to see details
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="selectedReceipt$">
                                    <div class="icon-with-text">
                                        <table id="receipt" width="100%">
                                            <thead [ngStyle]="(styles$|async)?.background">
                                                <tr>
                                                    <th>Description</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of (selectedReceipt$|async)?.receiptCartItems">
                                                    <td>
                                                        <p>{{item.cartItemInvoiceIdToPay}}</p>
                                                    </td>
                                                    <td class="text-right vertical-middle">{{item.cartItemAmountToPay /
                                                        100 | currency}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-right">Processing fee cost:</td>
                                                    <td class="text-right">
                                                        {{(selectedReceipt$|async)?.receiptProcessingFeeCost / 100 |
                                                        currency}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-right">Receipt Total:</td>
                                                    <td class="text-right">{{(selectedReceipt$|async)?.receiptAmount /
                                                        100 | currency}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-right">&nbsp;</td>
                                                    <td class="text-right">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-right">Receipt ID:</td>
                                                    <td class="text-right">{{(selectedReceipt$|async)?._id }}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="csc-card-header">
                                        <button mat-button (click)="onCreateReceiptPdf()">Click here for a printable
                                            version</button>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>