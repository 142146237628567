import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, concatMap, withLatestFrom, tap } from 'rxjs/operators';
import * as AppActions from './app.actions';
import { ContextService } from '../services/context.service';
import { LoginService } from '../services/login.service';
import { Store } from '@ngrx/store';
import { WorkflowService } from "../services/workflow.service";

@Injectable()
export class AppEffects {

    constructor(
        private actions$: Actions,
        private context: ContextService,
        private login: LoginService,
        private workflow: WorkflowService
    ) { }

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loginToFirebase),
            mergeMap(action => of(this.login.loginIntoFirebase(action.email, action.password)).pipe(
                map(() => AppActions.loginSuccess()),
                catchError(error =>
                    of(AppActions.loginFailure({ error })))
            ))
        )
    })

    loadFirebaseUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadFirebaseUser),
            mergeMap(action => this.context.loadFirebaseUser().pipe(
                map(user => AppActions.loadFirebaseUserSuccess({ user })),
                catchError(error =>
                    of(AppActions.loadFirebaseUserFailure({ error })))
            ))
        )
    })

    loadCitizen$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadCitizen),
            mergeMap(action => this.context.loadCitizen().pipe(
                map(citizen => AppActions.loadCitizenSuccess({ citizen })),
                catchError(error =>
                    of(AppActions.loadCitizenFailure({ error })))
            ))
        )
    })

    loadCityContext$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadCityContext),
            mergeMap(action => this.context.loadCityContext(action.cityLongCode).pipe(
                map(city => AppActions.loadCityContextSuccess({ city })),
                catchError(error =>
                    of(AppActions.loadCityContextFailure({ error })))
            ))
        )
    })

    loadCityAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadCityAccount),
            mergeMap(action => this.context.loadCityAccount(action.cityClientCode).pipe(
                map(account => AppActions.loadCityAccountSuccess({ account })),
                catchError(error =>
                    of(AppActions.loadCityContextFailure({ error })))
            ))
        )
    })

    addCitizenAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.addAccount),
            mergeMap(action => this.context.addCitizenAccount(action.uid, action.cityLongCode, action.accountNumber, action.accessCode, action.subledger).pipe(
                map(action => AppActions.addAccountSuccess({ message: 'Account succesfully added' })),
                catchError(error =>
                    of(AppActions.addAccountFailure({ error: error.details.error.details as string })))
            ))
        )
    })

    updateUserPreferences$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.updateUserPreferencesInFirebase),
            mergeMap(action => this.context.updateUserPreferences(action.preferences).pipe(
                map(() => AppActions.updateUserPreferencesInFirebaseSuccess()),
                catchError(error =>
                    of(AppActions.updateUserPreferencesInFirebaseFailure({ error })))
            ))
        )
    })

    loadWorkflowTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadWorkflowTemplateInitiate),
            mergeMap(action => this.workflow.getWorkflowTemplate(action.templateId, action.clientCode).pipe(
                map(workflowTemplate => AppActions.loadWorkflowTemplateSuccess({ workflowTemplate: workflowTemplate })),
                catchError(error =>
                    of(AppActions.loadWorkflowTemplateFailure({ error })))
            ))
        )
    })

    loadWorkflowInstance$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadWorkflowInstanceInitiate),
            mergeMap(action => this.workflow.getWorkflowInstance(action.instanceId, action.clientCode)
                .pipe(
                    map(data => AppActions.loadWorkflowInstanceSuccess({ workflowInstance: data.activeInstance, availableTasks: data.availableTasks })),
                    catchError(error =>
                        of(AppActions.loadWorkflowInstanceFailure({ error:error })))
                ))
        )
    })

    createWorkflowInstanceFromTemplateId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.createWorkflowInstanceFromTemplateIdInitiate),
            mergeMap(action => this.workflow.createWorkflowFromTemplateId(
                action.clientCode,
                action.templateId,
            )
                .pipe(
                    map(data => AppActions.createWorkflowInstanceFromTemplateIdSuccess({ workflowInstance: data.activeInstance, availableTasks: data.availableTasks })),
                    catchError(error => 
                        of(AppActions.createWorkflowInstanceFromTemplateIdFailure({ error }))
                    )
                ))
        )
    })

    formSubmissionTaskInitiate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.formTaskSubmissionInitiate),
            mergeMap(action => this.workflow.onSubmitFormAction(
                action.clientCode,
                action.instanceId,
                action.taskId,
                action.action,
                action.formSubmissionData
            )
                .pipe(
                    map(data => AppActions.formTaskSubmissionSuccess({ activeInstance: data.activeInstance, availableTasks: data.availableTasks })),
                    catchError(error => 
                        of(AppActions.formTaskSubmissionFailure({ error }))
                    )
                ))
        )
    })

    validationSubmissionTaskInitiate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.validationTaskSubmissionInitiate),
            mergeMap(action => this.workflow.onSelectAlgoliaItem(
                action.clientCode,
                action.instanceId,
                action.taskId,
                action.action,
                action.submissionData
            )
                .pipe(
                    map(data => AppActions.formTaskSubmissionSuccess({ activeInstance: data.activeInstance, availableTasks: data.availableTasks })),
                    catchError(error => 
                        of(AppActions.formTaskSubmissionFailure({ error }))
                    )
                ))
        )
    })

    // determineAvailableWorkflowTasks$ = createEffect( () => {
    //     return this.actions$.pipe(
    //         ofType(AppActions.determineAvailableWorkflowTasks),
    //         mergeMap(action => this.workflow.getAvailableTasksInWorkflowInstance(action.workflowInstance, action.role).pipe(
    //             map(availableTasks => AppActions.updateAvailableWorkflowTasks({ workflowTasks: availableTasks })),
    //             catchError(error =>
    //                 of(AppActions.determineAvailableWorkflowTasksFailure({ error })))
    //         ))
    //     )
    // })

}
