<div id="error404">

    <div class="content">

        <div class="error-code me">404</div>

        <div class="message me">Sorry we can't find the page you are looking for!</div>

        <button class="back-link me" (click)="backToHome()">Go back to Home Page</button>

    </div>

</div>
