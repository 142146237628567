import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// logrocket uncomment next two lines to run it as well as lines in oninit
//import * as LogRocket from 'logrocket';
//LogRocket.init('2l0r3z/csc');

import { Subscription } from 'rxjs';
import { AppSelectors } from './global/state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'Citizen Service Cloud';
  subscriptions: Subscription[] = [];

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
