import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PtState } from './pt.reducer';


const getPtFeatureState = createFeatureSelector<PtState>('ptState');

/**
 * utility billing selectors
 */

export const getSearchInvoices = createSelector(
   getPtFeatureState,
   state => state.ptSearchInvoices
);