<div class="main-container">
    <div class="logo-box">
        <img class="logo" [src]='logoImageUrl | async'>
    </div>
    <div class="csc-card-header">
        Web Payment Receipt Date:
        <span> {{ ((receipt$|async)?.receiptPaidAt).seconds * 1000 | date: 'medium' }}</span>
    </div>

    <div class="icon-with-text">
        <table id="receipt" width="100%">
            <thead [ngStyle]="(styles$|async)?.background">
                <tr>
                    <th>Description</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of (receipt$|async)?.receiptCartItems">
                    <td>
                        <p>{{item.cartItemInvoiceIdToPay}}</p>
                    </td>
                    <td class="text-right vertical-middle">{{item.cartItemAmountToPay / 100 | currency}}</td>
                </tr>
                <tr>
                    <td class="text-right">Processing fee cost:</td>
                    <td class="text-right">{{(receipt$|async)?.receiptProcessingFeeCost / 100 | currency}}</td>
                </tr>
                <tr>
                    <td class="text-right">Receipt Total:</td>
                    <td class="text-right">{{(receipt$|async)?.receiptAmount / 100 | currency}}</td>
                </tr>
                <tr>
                    <td class="text-right">&nbsp;</td>
                    <td class="text-right">&nbsp;</td>
                </tr>
                <tr>
                    <td class="text-right">Receipt ID:</td>
                    <td class="text-right">{{(receipt$|async)?._id }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
