import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadTaskComponent implements OnInit {

  uploadForm: any = { "components": [{ "label": "Upload file(s)", "tableView": false, "storage": "base64", "uploadOnly": true, "webcam": false, "fileTypes": [{ "label": "", "value": "" }], "multiple": true, "key": "uploadFileS", "type": "file", "input": true }] }
  uploadFormComponent = { "label": "Upload file(s)", "tableView": false, "storage": "base64", "uploadOnly": true, "webcam": false, "fileTypes": [{ "label": "", "value": "" }], "multiple": true, "key": "uploadFileS", "type": "file", "input": true };
  formData = { data: { uploadFileS: [] } };
  refreshForm;


  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.refreshForm = new EventEmitter();

  }

  onFormChange(e) {
    if (e.data) {
      this.formData = e.data;
    }
    console.log(this.formData);
  }

  onSaveForm() {
    // const image = this.clientFormData["clientLogoUpload"][0];
    // // there isn't always an image, so we need to trap 
    // if (image) {
    //   try {
    //     await this.cscData.storeClientLogo(image["originalName"], image["url"]);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log(this.formData);
  }

  clearUploadList() {

    // this.uploadForm.components.push({
    //   type: 'textfield',
    //   label: 'New Field',
    //   key: 'newField',
    //   input: true
    // });

    this.uploadForm.components.splice(0,1);
    this.uploadForm.components.push(this.uploadFormComponent);

    this.refreshForm.emit({
      form: this.uploadForm,
      submission: { data: { uploadFileS: [] } }
    });
    this.cdr.markForCheck();
  }


}
