import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CartItem, Citizen, CscClient, CscStyles, FirebaseUser, ShoppingCart } from '../../state/models/models';
import * as AppSelectors from '../../state/app.selectors';
import * as AppActions from '../../state/app.actions';
import { UbActions, UbSelectors } from '../../../feature-modules/utility-billing/state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StripeCoreService } from '../../services/stripe-core.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { TxActions } from 'src/app/feature-modules/property-taxes/state';
import { Router } from '@angular/router';
import { PtActions } from 'src/app/feature-modules/parking-tickets/state';
import { ParkingTicketsService } from 'src/app/feature-modules/parking-tickets/services/parking-tickets.service';

declare var Stripe;

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})

export class ShoppingCartComponent implements OnInit, OnDestroy {
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  stripe;
  stripeAccountId: string;
  card;
  cardErrors;
  checkoutForm: FormGroup;
  stripeElementsMessage$ = new BehaviorSubject(' ');
  showPaymentSpinner: boolean = false;

  shoppingCart$: Observable<ShoppingCart>;
  shoppingCart: ShoppingCart;
  cityContext: CscClient;
  user: FirebaseUser;
  styles$: Observable<CscStyles>;
  subscriptions: Subscription[] = [];
  isCheckoutActive: boolean = false;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private fxns: AngularFireFunctions,
    private stripeCore: StripeCoreService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.shoppingCart$ = this.store.select(AppSelectors.getShoppingCart);
    this.styles$ = this.store.select(AppSelectors.getStyles);
    let cityContextSubscription = this.store.select(AppSelectors.getCityContext).subscribe(cityContext => this.cityContext = cityContext);
    this.subscriptions.push(cityContextSubscription);
    let userSubscription = this.store.select(AppSelectors.getFirebaseUser).subscribe(user => this.user = user);
    this.subscriptions.push(userSubscription);
    let accountSubscription = this.store.select(AppSelectors.getCityAccount).subscribe(account => this.stripeAccountId = account);
    this.subscriptions.push(accountSubscription);
  }

  ngOnInit(): void {
    this.checkoutForm = this.fb.group({
      checkoutTotal: [0]
    });

    let shoppingCartSubscription = this.shoppingCart$.subscribe(cart => this.shoppingCart = cart);
    this.subscriptions.push(shoppingCartSubscription);
  }

  ngAfterViewInit(): void {
    if (this.shoppingCart.shoppingCartTotalAmount > 0) {
      this.stripe = Stripe(this.stripeCore.apiKey, {
        stripeAccount: this.stripeAccountId
      });
      const elements = this.stripe.elements();
      this.card = elements.create('card', {
        'style': {
          'base': {
            'width': '100%'
          }
        }
      });
      this.card.mount(this.cardElement.nativeElement);
      this.card.addEventListener('change', ({ error }) => {
        this.cardErrors = error && error.message;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if (this.shoppingCart.shoppingCartTotalAmount > 0) {
      this.card.removeEventListener('change');
      this.card.destroy();
    }
  }

  removeItemFromCart(item: CartItem): void {
    this.store.dispatch(AppActions.removeItemFromShoppingCart({ item: item }));
  }

  async onSubmit(form: Event): Promise<void> {

    this.isCheckoutActive = true;
    this.store.dispatch(AppActions.beginStripeCheckout());
    this.stripeElementsMessage$.next('Processing payment...');
    this.showPaymentSpinner = true;
    const { token, errorOne } = await this.stripe.createToken(this.card);

    const { paymentMethod, errorTwo } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: { token: token.id }
    });

    const functionValues = {
      clientCode: this.cityContext.clientCode,
      properName: `${this.user.userFirstName} ${this.user.userLastName}`,
      clientLongCode: this.cityContext.cityLongCode.toUpperCase(),
      cartItems: this.shoppingCart.shoppingCartItems,
      //convenienceFee: Math.round(this.shoppingCart.shoppingCartTotalConvenienceFees),
      convenienceFee: this.shoppingCart.shoppingCartTotalConvenienceFees,
      paymentMethodId: paymentMethod.id
    };

    console.log("function values",functionValues);
    const payInvoiceFunction = this.fxns.httpsCallable('csc_payInvoice');


    payInvoiceFunction(functionValues).toPromise()
      .then(receipt => {
        this.store.dispatch(AppActions.completeStripeCheckout());
        this.showPaymentSpinner = false;
        this.stripeElementsMessage$.next('Payment successful');
        setTimeout(() => {
        this.store.dispatch(AppActions.completeStripeCheckout());
          this.cdr.detectChanges();
          this.stripeElementsMessage$.next('');
          this.router.navigateByUrl('/receipts');
          const ubInvoices = functionValues.cartItems.filter(item => item.cartItemSubledger == "UB");
          const txNotices = functionValues.cartItems.filter(item => item.cartItemSubledger == "TX");
          const ptTickets = functionValues.cartItems.filter(item => item.cartItemSubledger == "PT");
          this.store.dispatch(UbActions.updateUtilityBillsAfterSuccessfulPayment({invoices: ubInvoices, receipts: receipt.receiptCartItems}));
          this.store.dispatch(TxActions.updateTaxNoticesAfterSuccessfulPayment({invoices: txNotices, receipts: receipt.receiptCartItems}));
          this.store.dispatch(PtActions.updateParkingTicketssAfterSuccessfulPayment({invoices: ptTickets, receipts: receipt.receiptCartItems}));
          this.store.dispatch(AppActions.clearShoppingCart());
          this.isCheckoutActive = false;
        }, 2000)
      })
      .catch(error => {
        console.log("error:" + error);
        this.showPaymentSpinner = false;
        this.stripeElementsMessage$.next('Unable to process payment.');
        //this.isCheckoutActive = false;
      })

  }
}
