import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { SelectCityComponent } from './global/components/select-city/select-city.component';
import { Error404Component } from './global/components/error404/error404.component';
import { LoginComponent } from './global/components/login/login.component';
import { Error403Component } from './global/components/error403/error403.component';
import { PrintReceiptComponent } from './global/components/receipts/print-receipt/print-receipt.component';
import { ParkingTicketsSearchComponent } from './unauthenticated/parking-tickets-unauthenticated/parking-tickets-search/parking-tickets-search.component';
import { ShoppingCartUnauthenticatedComponent } from './unauthenticated/shopping-cart-unauthenticated/shopping-cart-unauthenticated.component';
import { ReceiptsUnauthenticatedComponent } from './unauthenticated/receipts-unauthenticated/receipts-unauthenticated.component';
import { ParkingTicketReceiptsComponent } from './unauthenticated/parking-tickets-unauthenticated/parking-ticket-receipts/parking-ticket-receipts.component';

const redirectUrl = "/login";
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([redirectUrl]);

const routes: Routes = [
  {
    path: 'select-city',
    component: SelectCityComponent
  },
  {
    path: 'parking-tickets-unauth',
    component: ParkingTicketsSearchComponent
  },
  {
    path: 'shopping-cart-unauthenticated',
    component: ShoppingCartUnauthenticatedComponent
  },
  {
    path: 'receipts-unauthenticated',
    component: ReceiptsUnauthenticatedComponent
  },
  {
    path: 'parking-tickets-receipts',
    component: ParkingTicketReceiptsComponent
  },
  {
    path: 'login',
    children: [
      { path: '', component: LoginComponent },
      { path: ':id', component: LoginComponent }
    ]
  },
  {
    path: 'print-receipt',
    component: PrintReceiptComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'unknown-user',
    component: Error403Component
  },
  {
    path: '',
    loadChildren: () => import('./feature-modules/feature-container.module').then(m => m.FeatureContainerModule),
  },
  {
    path: '**',
    component: Error404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
