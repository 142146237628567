import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DocumentDefinitionPdfMake, Receipt } from "../state/models/models";

@Injectable({
  providedIn: "root"
})
export class ReceiptPrintService {

  constructor(private http: HttpClient) {

  }

  public createReceiptPdf(selectedReceipt: Receipt, logoImageUrl: any, styles: any): void {

    //generate DocumentDefinitionPdfMake object and send to Render server
    let postBody = this.generatePostBodyForReport(selectedReceipt, logoImageUrl, styles);
    console.log("POSTBODY" + JSON.stringify(postBody));

    //generate pdf for view in new tab and download
    const downloadPdfReceiptSubscription =
      this.http.post<any>('https://amiga-reporting-server-beta-qfhppkd7rq-nn.a.run.app/file',
        postBody,
        { responseType: 'arraybuffer' as 'json' })
        .subscribe(data => {
          const blob = new Blob([data], { type: 'application/pdf' });

          //open in new tab
          let fileUrl = window.URL.createObjectURL(blob);
          let tab = window.open();
          tab.location.href = fileUrl;
        },
          error => console.log(error)
        )
  }

  //generate post body - DocumentDefinitionPdfMake object
  private generatePostBodyForReport(selectedReceipt: Receipt, logoImageUrl: any, styles: any): DocumentDefinitionPdfMake {

    let reportHeaderDocDefinition = this.generateReportHeaderDocDefinition(logoImageUrl);
    let receiptTableContent = this.generateReceiptTableContentDocDefinition(selectedReceipt, styles);
    let receiptContentArray = [];
    receiptContentArray.push(reportHeaderDocDefinition);
    receiptContentArray.push(receiptTableContent);

    let docDefinition: DocumentDefinitionPdfMake = {
      pageSize: 'LETTER',
      pageOrientation: 'portrait',
      pageMargins: [0, 40, 0, 40],
      "content": receiptContentArray
    };

    return docDefinition;
  }

  private getSubledgerFullDescription(subledgerCode: string) {
    let subledgerFullName: string = "";
    switch (subledgerCode) {
      case "AL":
        subledgerFullName = "Animal License";
        break;
      case "BL":
        subledgerFullName = "Business License";
        break;
      case "BP":
        subledgerFullName = "Building Permit";
        break;
      case "UB":
        subledgerFullName = "Utility Billing";
        break;
      case "TX":
        subledgerFullName = "Property Tax";
        break;
    }
    return subledgerFullName;
  }

  private formatTimestampToUserFriendlyDate(timestamp: any) {
    let date = new Date(timestamp * 1000);
    return date.toDateString() + ", " + date.toLocaleTimeString();
  }

  private formatNumberToCurrency(numberToFormat: any) {
    let formattedNumber = (numberToFormat / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    return formattedNumber;
  }

  private doBlankRow(): any {
    let blankRow: any[] = [];
    let colBlankDesc: any = { "text": "-", "color": "#FFFFFF" };
    blankRow.push(colBlankDesc);
    let colBlankValue: any = { "text": "-", "color": "#FFFFFF" };
    blankRow.push(colBlankValue);
    return blankRow;
  }

  //generate doc definition for the receipt table
  private generateReceiptTableContentDocDefinition(selectedReceipt: Receipt, styles: any): any {

    let rows: any = [];

    let tableHeaderLine: any[] = []
    let headerCol1: any = { "text": "Description", "fillColor": styles.background["background-color"], "color": styles.text["color"], "alignment": "center", "bold": true };
    tableHeaderLine.push(headerCol1);
    let headerCol2: any = { "text": "Amount", "fillColor": styles.background["background-color"], "color": styles.text["color"], "alignment": "center", "bold": true };
    tableHeaderLine.push(headerCol2);
    rows.push(tableHeaderLine);

    let i = 1;
    selectedReceipt.receiptCartItems.forEach((item) => {
      let row: any[] = [];


      let descriptionArray: string[] = item.cartItemInvoiceIdToPay.split("-");
      let itemDescription = "Payment for " + this.getSubledgerFullDescription(descriptionArray[0]) + "\r\nAccount: " + descriptionArray[1] + "\r\nYear: " + descriptionArray[2];
      if (descriptionArray[0] == "UB") {
        itemDescription = itemDescription + "\r\nPeriod: " + descriptionArray[3];
      }

      let col1: any = { "text": itemDescription, "alignment": "right" };
      row.push(col1);
      let col2: any = { "text": this.formatNumberToCurrency(item.cartItemAmountToPay), "alignment": "right" };
      row.push(col2);
      rows.push(row);
    })

    let feeRow: any[] = [];

    let colFeeDesc: any = { "text": "Processing fee cost:", "alignment": "right" };
    feeRow.push(colFeeDesc);
    let colFeeAmt: any = { "text": this.formatNumberToCurrency(selectedReceipt.receiptProcessingFeeCost), "alignment": "right" };
    feeRow.push(colFeeAmt);
    rows.push(feeRow);

    let totalRow: any[] = [];

    let colTotalDesc: any = { "text": "Receipt Total:", "alignment": "right", "bold": true };
    totalRow.push(colTotalDesc);
    let colTotalAmt: any = { "text": this.formatNumberToCurrency(selectedReceipt.receiptAmount), "alignment": "right", "bold": true };
    totalRow.push(colTotalAmt);
    rows.push(totalRow);

    rows.push(this.doBlankRow());

    let idRow: any[] = [];
    let colIDDesc: any = { "text": "Receipt ID:", "alignment": "right", fontSize: 9 };
    idRow.push(colIDDesc);
    let colIDValue: any = { "text": selectedReceipt._id, "alignment": "right", fontSize: 9 };
    idRow.push(colIDValue);
    rows.push(idRow);

    let dateRow: any[] = [];
    let colDateDesc: any = { "text": "Payment Date:", "alignment": "right", fontSize: 9 };
    dateRow.push(colDateDesc);
 
    if(selectedReceipt.receiptPaidAt.seconds != undefined)
    {
      let colDateValue: any = { "text": this.formatTimestampToUserFriendlyDate(selectedReceipt.receiptPaidAt.seconds).substring(4), "alignment": "right", fontSize: 9 };
      dateRow.push(colDateValue);
    }
    else
    {
      let colDateValue: any = { "text": selectedReceipt.receiptPaidAt, "alignment": "right", fontSize: 9 };
      dateRow.push(colDateValue);
    }

    rows.push(dateRow);

    rows.push(this.doBlankRow());

    let mainReportTable: any = { "layout": "lightHorizontalLines", "table": { "headerRows": 1, widths: ["50%", "50%"], "body": rows } };

    let centeredTableDocDef = {
      columns: [
        { width: '*', text: '' },
        {
          width: "67%",
          layout: "lightHorizontalLines",
          table: mainReportTable.table,
          alignment: "center"
        },
        { width: '*', text: '' }
      ]
    }
    return centeredTableDocDef;
  }

  //generate doc defintion for header wth logo and date time 
  private generateReportHeaderDocDefinition(logoImageUrl: string): any {
    let reportHeaderDocDefinition = [
      {
        "margin": [
          45,
          15,
          45,
          40
        ],
        "columns": [
          { width: '*', text: '' },
          {
            "image": logoImageUrl,
            "alignment": "center",
            "width": 120
          },
          { width: '*', text: '' }
        ]
      }
    ]
    return reportHeaderDocDefinition;
  }
}