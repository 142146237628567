<mat-card class="csc-card">
    <mat-card-header>
        <ng-container *ngIf="data.subledger == 'TX'">
            <mat-card-title>Add a Property Tax Account</mat-card-title>
        </ng-container>
        <ng-container *ngIf="data.subledger == 'UB'">
            <mat-card-title>Add a Utility Billing Account</mat-card-title>
        </ng-container>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="addAccountForm">
            <div class="form-fields">
                <mat-form-field>
                    <mat-label>Account Number</mat-label>
                    <input matInput formControlName="accountNumber">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Access Code</mat-label>
                    <input matInput formControlName="accessCode">
                </mat-form-field>

                <!-- <div *ngIf="newUserSpinner" fxLayout="column" fxLayoutAlign="center center">
                    <app-spinner fxFlex></app-spinner>
                </div> -->
                <button mat-raised-button [ngStyle]="cityInfo.styles.button" type="submit" (click)="addAccount()"
                    [disabled]="addAccountForm.invalid">
                    Add Account
                </button>
                <hr>
                <!-- <ng-container *ngIf="messageObject.isError">
                    <p class="error-message">
                        {{ messageObject.message }}
                    </p>
                </ng-container> -->
                <ng-container *ngIf="error">
                    <p class="error-message">
                        {{ error$ | async }}
                    </p>
                </ng-container>
                <ng-container *ngIf="!messageObject.isError">
                    <p>
                        &nbsp;
                    </p>
                </ng-container>
            </div>
        </form>
    </mat-card-content>
</mat-card>
