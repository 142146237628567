import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Store } from '@ngrx/store';
import { constants } from 'fs';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContextService } from '../../services/context.service';
import { ReceiptPrintService } from '../../services/receipt-print.service';
import { AppActions, AppSelectors } from '../../state';
import { CscClient, CscStyles, FirebaseUser, Receipt } from '../../state/models/models';


@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss']
})
export class ReceiptsComponent implements OnInit, OnDestroy {

  receipts$: Observable<Receipt[]>;
  styles$: Observable<CscStyles>;
  selectedReceipt: Receipt;
  user: FirebaseUser;
  city: CscClient;
  subscriptions: Subscription[] = [];
  logoImageUrl: Observable<string>;

  constructor(
    private contextService: ContextService,
    private store: Store,
    private receiptPdfService: ReceiptPrintService,
    private storage: AngularFireStorage

  ) {
    this.styles$ = this.store.select(AppSelectors.getStyles);
    const userSubscription = this.store.select(AppSelectors.getFirebaseUser).subscribe(user => this.user = user);
    const citySubscription = this.store.select(AppSelectors.getCityContext).subscribe(city => this.city = city);
    this.subscriptions.push(userSubscription);
    this.subscriptions.push(citySubscription);
  }

  ngOnInit(): void {
    this.receipts$ =
      this.contextService.getCitizenReceipts(this.user.uid, this.city.cityLongCode.toUpperCase()).pipe(
        map((receipts: Receipt[]) => {
          receipts.sort((a, b) => (a.receiptPaidAt > b.receiptPaidAt ? -1 : 1));
          return receipts;
        })
      );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSelectReceipt(receipt: Receipt): void {
    this.selectedReceipt = receipt;
    this.store.dispatch(AppActions.selectReceiptToPrint({ receipt: this.selectedReceipt }));
  }

  onPrintReceipt(): void {
    window.open('/print-receipt');
  }

  onCreateReceiptPdf(): void {

    const subscriptionLogo = this.store.select(AppSelectors.getLogoRef)
      .subscribe(ref => {

        let logoPath = ref.split("/");

        let logoUrl = "https://storage.googleapis.com/amiga-reporting-images/" + this.city.clientCode + "/" + logoPath[1];

        const subscriptionStyle = this.styles$.subscribe(style => {
          if (style) {
            //pass the logo url as string and style object to receipt pdf service
            this.receiptPdfService.createReceiptPdf(this.selectedReceipt, logoUrl, style);
          }
        }
        )
        this.subscriptions.push(subscriptionStyle);
      })
    this.subscriptions.push(subscriptionLogo);
  }
}