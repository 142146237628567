import { createAction, props } from '@ngrx/store';
import { CartItem, Invoice, Receipt, SubledgerAccount, Tab } from 'src/app/global/state/models/models';



/**
 * page actions below - actions that are dispatched from pages
 */
export const loadBusinessLicenseTabs = createAction(
    '[BL] initiate - load business license tabs',
    props<{ cityLongCode: string }>()
);

export const loadBusinessLicensesForCitizen = createAction(
    '[BL] initiate - load business licenses for citizen',
    props<{ cityLongCode: string }>()
);

export const selectBlAccount = createAction(
    '[BL] select a BL account',
    props<{ account: SubledgerAccount }>()
);

export const selectBlInvoice = createAction(
    '[BL] select a BL invoice',
    props<{ invoice: Invoice }>()
);

/**
 * calculation actions below - actions that require complex calculations to be performed
 */

export const updateBusinessLicensesAfterSuccessfulPayment = createAction(
    '[cart] - update BL invoices after successful payment',
    props<{invoices: CartItem[], receipts: CartItem[]}>()
)

 /**
 * api actions below - actions that require use of external endpoints
 */
export const loadBusinessLicenseTabsSuccess = createAction(
    '[BL] success - load business license tabs',
    props<{ tabs: Tab[] }>()
)

export const loadBusinessLicenseTabsFailure = createAction(
    '[BL] failure - load business license tabs',
    props<{ error: string }>()
)

export const loadBusinessLicensesForCitizenSuccess = createAction(
    '[BL] success - load business licenses for citizen',
    props<{ invoices: Invoice[] }>()
)

export const loadBusinessLicensesForCitizenFailure = createAction(
    '[BL] failure - load business licenses for citizen',
    props<{ error: string }>()
)