import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TxState } from './tx.reducer';


const getTxFeatureState = createFeatureSelector<TxState>('txState');

/**
 * property tax selectors
 */

 export const getTxTabs = createSelector(
    getTxFeatureState,
    state => state.txTabs
 );

 export const getTxInvoices = createSelector(
   getTxFeatureState,
   state => state.txInvoices
);

export const getSelectedAccount = createSelector(
   getTxFeatureState,
   state => state.txSelectedAccount
);

export const getSelectedInvoice = createSelector(
   getTxFeatureState,
   state => state.txSelectedInvoice
);

export const getSelectedAccountInvoices = createSelector(
   getTxFeatureState,
   state => state.txSelectedAccountInvoices
);