import { createAction, props } from '@ngrx/store';
import { CartItem, Invoice, Receipt, SubledgerAccount, Tab } from 'src/app/global/state/models/models';

/**
 * calculation actions below - actions that require complex calculations to be performed
 */

export const updateParkingTicketssAfterSuccessfulPayment = createAction(
    '[cart] - update PT invoices after successful payment',
    props<{invoices: CartItem[], receipts: CartItem[]}>()
)


//search for parkings tickets by license plate or ticket number

export const loadParkingTicketsByLicensePlate = createAction(
    '[PT] initiate - load parking tickets for citizen by license plate',
    props<{ cityLongCode: string, licensePlate: string, paymentStatus: string }>()
);

export const loadParkingTicketsByTicketNumber = createAction(
    '[PT] initiate - load parking tickets for citizen by ticket number',
    props<{ cityLongCode: string, ticketNumber: string, paymentStatus: string }>()
);

export const loadParkingTicketReceipts = createAction(
    '[PT] initiate - load parking ticket receipts for citizen',
    props<{ cityLongCode: string, receiptEmail: string }>()
);

export const loadParkingTicketsByLicensePlateSuccess = createAction(
    '[PT] success - load parking tickets for citizen by license plate success',
    props<{ invoices: Invoice[] }>()
)

export const loadParkingTicketsByLicensePlateFailure = createAction(
    '[PT] failure - load parking tickets for citizen by license plate failure',
    props<{ error: string }>()
)

export const loadParkingTicketsByTicketNumberSuccess = createAction(
    '[PT] success - load parking tickets for citizen by ticket number success',
    props<{ invoices: Invoice[] }>()
)

export const loadParkingTicketsByTicketNumberFailure = createAction(
    '[PT] failure - load parking tickets for citizen by ticket number failure',
    props<{ error: string }>()
)

export const loadParkingTicketReceiptsSuccess = createAction(
    '[PT] success - load parking ticket receipts success',
    props<{ receipts: Receipt[] }>()
)

export const loadParkingTicketReceiptFailure = createAction(
    '[PT] failure - load parking tickets receipt failure',
    props<{ error: string }>()
)

export const loadParkingTicketReceipt = createAction(
    '[PT] - get PT receipt',
    props<{receipt: Receipt}>()
)

export const resetParkingTicketState = createAction(
    '[PT] - reset parking ticket state'
)