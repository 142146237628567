import { createAction, props } from '@ngrx/store';
import { Citizen, CscClient, CartItem, FirebaseUser, UserPreferences, Receipt } from './models/models';
import { TaskTemplate } from './models/workflow/taskTemplate';
import { WorkflowInstance } from './models/workflow/workflowInstance';
import { WorkflowTemplate } from './models/workflow/workflowTemplate';

/**
 * page actions below - actions that are dispatched from pages
 */
//#region 
export const loginToFirebase = createAction(
    '[login] initiate - login to Firebase',
    props<{ email: string, password: string }>()
)

export const loadFirebaseUser = createAction(
    '[login] initiate - load firebaseUser details'
);

export const loadCitizen = createAction(
    '[login] initiate - load citizen details'
);

export const loadCityContext = createAction(
    '[app] - initiate load city context',
    props<{cityLongCode: string}>()
)

export const loadCityAccount = createAction(
    '[app] - initiate load city account',
    props<{cityClientCode: string}>()
)

export const clearState = createAction(
    '[app] - clear state'
)

export const clearUser = createAction(
    '[app] - clear user'
)

export const clearError = createAction(
    '[app] - clear error message',
    props<{error: ''}>()
)

export const selectReceiptToPrint = createAction(
    '[receipts] - select receipt',
    props<{receipt: Receipt}>()
)
//#endregion

/**
 * calculation actions below - actions that require complex calculations to be performed
 */
//#region 
export const addItemToShoppingCart = createAction(
    '[cart] - add item to cart',
    props<{item: CartItem, subledger: string}>()
)

export const removeItemFromShoppingCart = createAction(
    '[cart] - remove item from cart',
    props<{item: CartItem}>()
)

export const beginStripeCheckout = createAction(
    '[cart] - begin Stripe checkout'
)

export const completeStripeCheckout = createAction(
    '[cart] - complete Stripe checkout'
)

export const clearShoppingCart = createAction(
    '[cart] = clear shopping cart'
)
//#endregion

/**
 * api actions below - actions that require use of external endpoints
 */
//#region 
export const loginSuccess = createAction(
    '[login] success - successfully logged into Firebase'
)

export const loginFailure = createAction(
    '[login] failure - login error',
    props<{ error: string }>()
)

export const loadFirebaseUserSuccess = createAction(
    '[login] success - load firebase user from cloud',
    props<{ user: FirebaseUser }>()
)

export const loadFirebaseUserFailure = createAction(
    '[login] failure - load firebase user from cloud',
    props<{ error: string }>()
)

export const loadCitizenSuccess = createAction(
    '[login] success - load citizen from cloud',
    props<{ citizen: Citizen }>()
)

export const loadCitizenFailure = createAction(
    '[login] failure - load citizen from cloud',
    props<{ error: string }>()
)

export const loadCityContextSuccess = createAction(
    '[app] - success load city context',
    props<{ city: CscClient }>()
)

export const loadCityContextFailure = createAction(
    '[app] - failure load city context',
    props<{ error: string}>()
)

export const loadCityAccountSuccess = createAction(
    '[app] - success load city account',
    props<{ account: string }>()
)

export const loadCityAccountFailure = createAction(
    '[app] - failure load city account',
    props<{ error: string}>()
)

export const shoppingCartCheckout = createAction(
    '[app] - shopping cart checkout'
)

export const updateUserPreferencesInFirebase = createAction(
    '[user] - initiate update user preferences',
    props<{ preferences: UserPreferences }>()
)

export const updateUserPreferencesInFirebaseSuccess = createAction(
    '[user] - success - update user preferences',
)

export const updateUserPreferencesInFirebaseFailure = createAction(
    '[user] - failure - update user preferences',
    props<{ error: string }>()
)

export const addAccount = createAction(
    '[TX/UB] - initiate - addAccount',
    props<{ uid: string, cityLongCode: string, accountNumber: string, accessCode: string, subledger: string }>()
)

export const addAccountSuccess = createAction(
    '[TX/UB] - success - addAccount',
    props<{ message: string }>()
)

export const addAccountFailure = createAction(
    '[TX/UB] - failure - addAccount',
    props<{ error: string }>()
)
//#endregion

/**
 * workflow related actions below - internal and external
 */

//#region create and load new workflow instance from template id
export const createWorkflowInstanceFromTemplateIdInitiate = createAction(
    '[workflow] initiate - create new instance from template id',
    props<{ clientCode: string, templateId: string }>()
)

export const createWorkflowInstanceFromTemplateIdSuccess = createAction(
    '[workflow] success - create new instance from template id',
    props<{ workflowInstance: WorkflowInstance, availableTasks: TaskTemplate[] }>()
)

export const createWorkflowInstanceFromTemplateIdFailure = createAction(
    '[workflow] failure - create new instance from template id',
    props<{ error: string }>()
)

//#endregion

//#region load workflow template
export const loadWorkflowTemplateInitiate = createAction(
    '[workflow] initiate - retrieve workflow template',
    props<{ templateId: string, clientCode: string }>()
)

export const loadWorkflowTemplateSuccess = createAction(
    '[workflow] success - workflow template retrieved',
    props<{ workflowTemplate: WorkflowTemplate }>()
)

export const loadWorkflowTemplateFailure = createAction(
    '[workflow] failure - error retrieving workflow template',
    props<{ error: string }>()
)
//#endregion

//#region load workflow template and available tasks
export const loadWorkflowInstanceInitiate = createAction(
    '[workflow] initiate - retrieve workflow instance',
    props<{ instanceId: string, clientCode: string }>()
)

export const loadWorkflowInstanceSuccess = createAction(
    '[workflow] success - workflow instance retrieved',
    props<{ workflowInstance: WorkflowInstance, availableTasks: TaskTemplate[] }>()
)

export const loadWorkflowInstanceFailure = createAction(
    '[workflow] failure - error retrieving workflow instance',
    props<{ error: string }>()
)
//#endregion

//#region clear workflow state when component destroyed
export const clearWorkflowState = createAction(
    '[workflow] clear workflow state',
)
//#endregion

//#region TASK - FORM SUBMISSION ACTIONS HERE
export const formTaskSubmissionInitiate = createAction(
    '[workflow - formTask] submission initiate',
    props<{ clientCode: string, instanceId: string, taskId: string, action: string, formSubmissionData: any }>()
)

export const formTaskSubmissionSuccess = createAction(
    '[workflow - formTask] submission success',
    props<{ activeInstance: WorkflowInstance, availableTasks: TaskTemplate[]  }>()
)

export const formTaskSubmissionFailure = createAction(
    '[workflow - formTask] submission failure',
    props<{ error: any }>()
)
//#endregion

//#region TASK - FORM SUBMISSION ACTIONS HERE
export const validationTaskSubmissionInitiate = createAction(
    '[workflow - validation task] validationTaskSubmissionInitiate',
    props<{ clientCode: string, instanceId: string, taskId: string, action: string, submissionData: any }>()
)



//#region TASK - UPLOAD ACTIONS HERE
//#endregion

//#region TASK - APPROVAL ACTIONS HERE
//#endregion

