import { Injectable } from '@angular/core';

declare var Stripe;

@Injectable({
  providedIn: 'root'
})
export class StripeCoreService {

  //apiKey = 'pk_live_51HCpdzEsjXRQaaBxJRuqkqyTcEYB2kVWH3ykeex8zHSKBv6LjFouwWW2j3l7gWVZ5xwccIZVJhnOMimZEh34T3Zt00ahK6G3Vr';
  apiKey = 'pk_test_51HCpdzEsjXRQaaBx1dKpjstZyOUbMMecwoIKSJYGsqCnciLa9AJyemcOtVTt0lzE9W1Rt2rOFHqVoh1tgG0KQypg00YJxPreFN';
  stripe;

  constructor() {
    this.stripe = Stripe(this.apiKey);
  }
}
