import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppActions } from 'src/app/global/state';
import { AlgoliaValidationTaskInstance } from 'src/app/global/state/models/workflow/algoliaValidationTaskInstance';

@Component({
  selector: 'app-validation-task',
  templateUrl: './validation-task.component.html',
  styleUrls: ['./validation-task.component.scss']
})
export class ValidationTaskComponent implements OnInit {

  @Input() apiKey: string;
  @Input() appId: string;
  @Input() indexName: string;
  @Input() routing: boolean;
  @Input() task: AlgoliaValidationTaskInstance
  @Input() instanceId: string

  showResults: boolean = false;
  algoliaConfig;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    console.log("validation task");
    this.algoliaConfig = {
      apiKey: this.apiKey,
      appId: this.appId,
      indexName: this.indexName,
      routing: this.routing
    }
  }

  onSelectItem(hit) {
    console.log(hit);
    try {
      this.store.dispatch(AppActions.validationTaskSubmissionInitiate({
        clientCode: 'AMAIS',
        instanceId: this.instanceId,
        taskId: this.task.id.toString(),
        action: "algoliaValidatedDataSubmitted",
        submissionData: hit["objectID"]
      }))
    } catch (error) {
      console.log(error);
    }
  }


}
