// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    
    //apiKey: "AIzaSyAf9lvtvbCZEYPyzzGFU9H2yYRf5vacvlA",
    //authDomain: "citreq-dev.firebaseapp.com",
    //databaseURL: "https://citreq-dev.firebaseio.com",
    //projectId: "citreq-dev",
    //storageBucket: "citreq-dev.appspot.com",
    //messagingSenderId: "456344383865",
    //appId: "1:456344383865:web:a6ea41f047a2ccb481ffa8",
    //measurementId: "G-G1WB4RJ063"

     apiKey: "AIzaSyBQdg_XzUhYZLyg5KuoIR0f4kfgQpGidjg",
     authDomain: "amais-suite-staging.firebaseapp.com",
     databaseURL: "https://amais-suite-staging.firebaseio.com",
     projectId: "amais-suite-staging",
     storageBucket: "amais-suite-staging.appspot.com",
     messagingSenderId: "970129185414",
     appId: "1:970129185414:web:52b098209645f29b250f07"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


// apiKey: "AIzaSyBQdg_XzUhYZLyg5KuoIR0f4kfgQpGidjg",
// authDomain: "amais-suite-staging.firebaseapp.com",
// databaseURL: "https://amais-suite-staging.firebaseio.com",
// projectId: "amais-suite-staging",
// storageBucket: "amais-suite-staging.appspot.com",
// messagingSenderId: "970129185414",
// appId: "1:970129185414:web:52b098209645f29b250f07"

// apiKey: "AIzaSyAf9lvtvbCZEYPyzzGFU9H2yYRf5vacvlA",
// authDomain: "citreq-dev.firebaseapp.com",
// databaseURL: "https://citreq-dev.firebaseio.com",
// projectId: "citreq-dev",
// storageBucket: "citreq-dev.appspot.com",
// messagingSenderId: "456344383865",
// appId: "1:456344383865:web:a6ea41f047a2ccb481ffa8",
// measurementId: "G-G1WB4RJ063"
