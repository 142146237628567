import { createAction, props } from '@ngrx/store';
import { CartItem, Invoice, SubledgerAccount, Tab } from 'src/app/global/state/models/models';



/**
 * page actions below - actions that are dispatched from pages
 */
export const loadPropertyTaxTabs = createAction(
    '[TX] initiate - load property tax tabs',
    props<{ cityLongCode: string }>()
);

export const loadTaxNoticesForCitizen = createAction(
    '[TX] initiate - load tax notices for citizen',
    props<{ cityLongCode: string }>()
);

export const selectTxAccount = createAction(
    '[TX] select a TX account',
    props<{ account: SubledgerAccount }>()
);

export const selectTxInvoice = createAction(
    '[TX] select a TX invoice',
    props<{ invoice: Invoice }>()
);

/**
 * calculation actions below - actions that require complex calculations to be performed
 */

export const updateTaxNoticesAfterSuccessfulPayment = createAction(
    '[cart] - update tax notices after successful payment',
    props<{invoices: CartItem[], receipts: CartItem[]}>()
)

 /**
 * api actions below - actions that require use of external endpoints
 */
export const loadPropertyTaxTabsSuccess = createAction(
    '[TX] success - load property tax tabs',
    props<{ tabs: Tab[] }>()
)

export const loadPropertyTaxTabsFailure = createAction(
    '[TX] failure - load property tax tabs',
    props<{ error: string }>()
)

export const loadTaxNoticesForCitizenSuccess = createAction(
    '[UB] success - load tax notices for citizen',
    props<{ invoices: Invoice[] }>()
)

export const loadTaxNoticesForCitizenFailure = createAction(
    '[UB] failure - load tax notices for citizen',
    props<{ error: string }>()
)