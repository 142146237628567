import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { Invoice, Receipt } from '../../../global/state/models/models';

@Injectable({
    providedIn: 'root'
})
export class ParkingTicketsService {

    constructor(
        private db: AngularFirestore,
        private afAuth: AngularFireAuth,
        private fxns: AngularFireFunctions,
        private httpClient: HttpClient
    ) { 
    }

    generateId(): string {
        return this.db.createId();
    }

    /*
    getParkingTicketsByLicensePlate(cityLongCode: string, licensePlate: string, paymentStatus: string): Observable<Invoice[]> {
        return this.httpClient.get<Invoice[]>(`http://localhost:5001/citreq-dev/us-central1/searchTickets?searchBy=licensePlate&licensePlate=${licensePlate}&cityLongCode=${cityLongCode}&isOutstanding=${paymentStatus}`);
    }


    getParkingTicketsByTicketNumber(cityLongCode: string, ticketNumber: string, paymentStatus: string): Observable<Invoice[]> {
        return this.httpClient.get<Invoice[]>(`http://localhost:5001/citreq-dev/us-central1/searchTickets?searchBy=ticketNumber&ticketNumber=${ticketNumber}&cityLongCode=${cityLongCode}&isOutstanding=${paymentStatus}`);
    }
    */
    
    getParkingTicketsByLicensePlate(cityLongCode: string, licensePlate: string, paymentStatus: string): Observable<Invoice[]> {
        //return this.httpClient.get<Invoice[]>(`http://localhost:5001/citreq-dev/us-central1/searchTickets?searchBy=licensePlate&licensePlate=${licensePlate}&cityLongCode=${cityLongCode}&isOutstanding=Y`);
        //return this.httpClient.get<Invoice[]>(`https://us-central1-citreq-dev.cloudfunctions.net/searchTickets?searchBy=licensePlate&licensePlate=${licensePlate}&cityLongCode=${cityLongCode}&isOutstanding=Y`);
        return this.httpClient.get<Invoice[]>(`https://us-central1-amais-suite-staging.cloudfunctions.net/searchTickets?searchBy=licensePlate&licensePlate=${licensePlate}&cityLongCode=${cityLongCode}&isOutstanding=Y`);
    }

    getParkingTicketsByTicketNumber(cityLongCode: string, ticketNumber: string, paymentStatus: string): Observable<Invoice[]> {
        //return this.httpClient.get<Invoice[]>(`http://localhost:5001/citreq-dev/us-central1/searchTickets?searchBy=ticketNumber&ticketNumber=${ticketNumber}&cityLongCode=${cityLongCode}&isOutstanding=Y`);
        //return this.httpClient.get<Invoice[]>(`https://us-central1-citreq-dev.cloudfunctions.net/searchTickets?searchBy=ticketNumber&ticketNumber=${ticketNumber}&cityLongCode=${cityLongCode}&isOutstanding=Y`);
        return this.httpClient.get<Invoice[]>(`https://us-central1-amais-suite-staging.cloudfunctions.net/searchTickets?searchBy=ticketNumber&ticketNumber=${ticketNumber}&cityLongCode=${cityLongCode}&isOutstanding=Y`);
    }

    getParkingTicketReceipts(cityLongCode: string, receiptEmail: string): Observable<Receipt[]> {
        return this.httpClient.get<Receipt[]>(`http://localhost:5001/citreq-dev/us-central1/searchReceipts?receiptEmail=${receiptEmail}&cityLongCode=${cityLongCode}`);
    }
}
