import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Citizen, CscClient } from '../../state/models/models';
import * as AppSelectors from '../../../global/state/app.selectors';
import * as AppActions from '../../../global/state/app.actions';
import { UbActions } from 'src/app/feature-modules/utility-billing/state';
import { TxActions } from 'src/app/feature-modules/property-taxes/state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlActions } from 'src/app/feature-modules/business-license/state';

export interface addAccountData {
  subledger: string,
  cityInfo: Observable<CscClient>
}

export interface messageObject {
  message: string,
  isError: boolean
}

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit, OnDestroy {

  addAccountForm: FormGroup;
  citizen: Citizen;
  cityInfo: CscClient;
  messageObject: messageObject;
  subscriptions: Subscription[] = [];
  error: string = '';
  error$ = new BehaviorSubject('');

  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private store: Store,
    private dialogRef: MatDialogRef<AddAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: addAccountData
  ) {
    let cityInfoSubscription = this.store.select(AppSelectors.getCityContext).subscribe(cityInfo => this.cityInfo = cityInfo);
    let citizenSubscription = this.store.select(AppSelectors.getCitizen).subscribe(citizen => this.citizen = citizen);
    let errorSubscription = this.store.select(AppSelectors.getError)
      .subscribe(error => this.error$.next(error));
    this.subscriptions.push(cityInfoSubscription);
    this.subscriptions.push(citizenSubscription);
    this.subscriptions.push(errorSubscription);
  }

  ngOnInit(): void {
    this.addAccountForm = this.fb.group({
      accountNumber: ['', Validators.required],
      accessCode: ['', Validators.required]
    });
    this.messageObject = { message: '', isError: false };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  addAccount(): void {
    let accountNumberIndex = -2; // set this to negative number for logic check on whether to add a new account or not.  If there are no accounts at all, or the requested account is not already added, then it will add an account

    if (this.citizen.accounts) {
      const accounts = this.citizen.accounts.filter(account => account.accountSubledger == this.data.subledger);
      accountNumberIndex = accounts.findIndex(account => account.accountNumber == this.addAccountForm.value.accountNumber);
    }

    if (accountNumberIndex < 0 ) {
      this.store.dispatch(AppActions.addAccount(
        {
          uid: this.citizen.uid,
          cityLongCode: this.cityInfo.cityLongCode.toUpperCase(),
          subledger: this.data.subledger,
          accountNumber: this.addAccountForm.value.accountNumber,
          accessCode: this.addAccountForm.value.accessCode
        }));
      setTimeout(() => {
        this.dialogRef.close();
        if (this.error$.getValue() != "") {
          this.snackbar.open(this.error$.getValue(), "", { duration: 3000, verticalPosition: 'top' });
        }
        this.error$.next('');
        this.store.dispatch(AppActions.clearError({ error: '' }));
        this.store.dispatch(UbActions.loadUtilityBillsForCitizen({cityLongCode: this.cityInfo.cityLongCode.toUpperCase()}));
        this.store.dispatch(TxActions.loadTaxNoticesForCitizen({cityLongCode: this.cityInfo.cityLongCode.toUpperCase()}));
        this.store.dispatch(BlActions.loadBusinessLicensesForCitizen({ cityLongCode: this.cityInfo.cityLongCode.toUpperCase() }));
      }, 2000)
    }
    if (accountNumberIndex > -1) {
      this.snackbar.open("You have already added that account", "", { duration: 2000, verticalPosition: 'top' });
      this.error$.next('You have already added that account');
      setTimeout(() => {
        this.error$.next('');
        this.dialogRef.close();
      }, 2000)
    }
  }

}
