import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState } from './app.reducers';

const getAppFeatureState = createFeatureSelector<UserState>('app');

/**
 * city context selectors
 */

 export const getCityContext = createSelector(
     getAppFeatureState,
     state => state.cityInfo
 );

 export const getCityAccount = createSelector(
     getAppFeatureState,
     state => state.account
 );

 export const getLogoRef = createSelector(
     getAppFeatureState,
     state => state.cityInfo.logoRef
 );

 export const getStyles = createSelector(
    getAppFeatureState,
    state => state.cityInfo.styles
);

export const getFirebaseUser = createSelector(
    getAppFeatureState,
    state => state.user
);

export const getCitizen = createSelector(
    getAppFeatureState,
    state => state.citizen
);

export const getCitizenAccounts = createSelector(
    getAppFeatureState,
    state => state.citizen.accounts
);

export const arePaymentsEnabled = createSelector(
    getAppFeatureState,
    state => state.cityInfo.cityProductAccess.CART
)

export const getShoppingCart = createSelector(
    getAppFeatureState,
    state => state.shoppingCart
)

export const getConvenienceFees = createSelector(
    getAppFeatureState,
    state => state.cityInfo.convenienceFees
)

export const getError = createSelector(
    getAppFeatureState,
    state => state.error
)

export const getReceiptToPrint = createSelector(
    getAppFeatureState,
    state => state.receiptToPrint
)

export const getActiveWorkflowTemplate = createSelector(
    getAppFeatureState,
    state => state.workflow.activeTemplate
)

export const getActiveWorkflowInstance = createSelector(
    getAppFeatureState,
    state => state.workflow.activeInstance
)

export const getAvailableWorkflowTasks = createSelector(
    getAppFeatureState,
    state => state.workflow.availableTasks
)
