/**
 * Login Service is used for the following:
 * - user logging into application
 * - user resetting their password
 * - user signing up to the service
 */

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Store } from '@ngrx/store';
import * as AppActions from '../state/app.actions';
import { take } from 'rxjs/operators';

interface NewUser {
    userFirstName: string,
    userLastName: string,
    userEmail: string,
    userPassword: string,
    userCityCode: string
}

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private fxns: AngularFireFunctions,
    private store: Store
  ) {  }

/**
 * gets the user's cityCode from custom claim
 */
async getUserCityCode() {
  const token = await this.afAuth.idTokenResult.pipe(take(1)).toPromise();
  const cityCode = token.claims["cityCode"];
  return cityCode;
}

/**
 * Attempts to log a user into Firebase with given credentials
 * 
 * @param email {string} - user email account for logging in
 * @param password {string} - user password for loggin in
 * @returns Firebase user credential
 */
  async loginIntoFirebase(email: string, password: string): Promise<firebase.default.auth.UserCredential>
  {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Logs a user out of the application.
   * - signs out of Firebase
   * - clears localStorage
   * - resets the store
   * - navigates back to /select-city
   * 
   * @returns Promise<void>
   */
  async logout(): Promise<void> {
    await this.afAuth.signOut();
    this.store.dispatch(AppActions.clearState());
  }

  /**
   * Send a password reset email to a user with the email supplied
   * 
   * @param email {string} - email address for account requesting password reset
   * @returns Promise<void>
   */
  async forgotPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  /**
   * Creates a new user in Firebase based on information provided by the user.
   * User is created by calling a Firebase function that sets up the user in the "User" and "Citizen"
   * collections
   * 
   * @param newUser {NewUser} - data for creating a new user according to required interface
   * @returns Promise<void>
   */
  async createNewUser(newUser:NewUser): Promise<void> {
    const callable = this.fxns.httpsCallable('createCitizen');
    return callable(newUser).toPromise();
  }
}
