export interface AccountMaster {
    _id?: string;
    accountAccessCode: string;
    accountNumber: string;
    subledger: string;
    globalAccountNumber: string[];
}

export interface AlAnimalInfo {
    _id: string;
    alDateApplied: Date | string;
    alPetInfo: AlPetInfo[];
    alOwner: Citizen;
}

export interface AlPetInfo {
    alLicenseStatus: LicenseStatusEnum | string;
    alLicenseStartDate?: Date | string;
    alLicenseExpiryDate?: Date | string;
    alPetType: AlPetTypeEnum | string;
    alBirthDate: Date | string;
    alDogName: string;
    alSex: AlSexEnum | string;
    alBreed?: AlDogBreedEnum | string;
    alIdInfo: AlIdInfo[] | string;
    alHealthInfo?: AlHealthInfo[] | string;
    alLicenseInfo?: AlLicenseInfo | string;
}

export interface AlLicenseInfo {
    alDateApplied: Date | string;
}

export enum LicenseStatusEnum {
    applicationInProcess = "Application by citizen in process",
    applicationUnpaid = "Application made, but unpaid",
    applicationPaid = "Application made, paid and pending review",
    licenseActive = "License is paid and active",
    licenseExpired = "License is expired"
}

export interface AlIdInfo {
    alIdType: AlIdTypeEnum;
    alIdNumber: string
}

export interface AlHealthInfo {
    type: string;
    description?: string;
    date: Date | string;
}

export enum AlIdTypeEnum {
    tattoo = "tattoo",
    tag = "tag",
    chip = "chip"
}

export enum AlPetTypeEnum {
    dog = "dog",
    cat = "cat",
    lizard = "lizard",
    other = "other"
}

//export interface Citizen { } // should be imported from another file

export enum AlColourEnum {
    apricot = "Apricot",
    black = "Black",
    blue = "Blue",
    brown = "Brown",
    cream = "Cream",
    golden = "Golden",
    grey = "Grey",
    liver = "Liver",
    red = "Red",
    seal = "Seal",
    tan = "Tan",
    wheaton = "Wheaton",
    white = "White",
    yellow = "Yellow"
}

export enum AlSexEnum {
    femaleSpayed = "Female - Spayed",
    femaleUnaltered = "Female - Unaltered",
    maleNeutered = "Male - Neutered",
    maleUnaltered = "Male - Unaltered"
}

export enum AlDogBreedEnum {
    abruzzenhund = "Abruzzenhund",
    affenpinscher = "Affenpinscher",
    afghan_hound = "Afghan Hound",
    africanis = "Africanis",
    aidi = "Aidi",
    ainu_dog = "Ainu Dog",
    airedale_terrier = "Airedale Terrier",
    akbash_dog = "Akbash Dog",
    akitas = "Akitas",
    akita_american = "Akita (American)",
    akita_inu_japanese = "Akita Inu (Japanese)",
    alano_español = "Alano Español",
    alapaha_blue_blood_bulldog = "Alapaha Blue Blood Bulldog",
    alaskan_husky = "Alaskan Husky",
    alaskan_klee_kai = "Alaskan Klee Kai",
    alaskan_malamute = "Alaskan Malamute",
    alopekis = "Alopekis",
    alpine_dachsbracke = "Alpine Dachsbracke",
    american_allaunt = "American Allaunt",
    american_alsatian = "American Alsatian",
    american_black_and_tan_coonhound = "American Black and Tan Coonhound",
    american_blue_gascon_hound = "American Blue Gascon Hound",
    american_blue_lacy = "American Blue Lacy",
    american_bull_molosser = "American Bull Molosser",
    american_bulldog = "American Bulldog",
    american_bullnese = "American Bullnese",
    american_bully = "American Bully",
    american_cocker_spaniel = "American Cocker Spaniel",
    american_english_coonhound = "American English Coonhound",
    american_eskimo_dog = "American Eskimo Dog",
    american_foxhound = "American Foxhound",
    american_hairless_terrier = "American Hairless Terrier",
    american_indian_dog = "American Indian Dog",
    american_losze_pugg = "American Lo-Sze Pugg",
    american_mastiff = "American Mastiff",
    american_mastiff_panja = "American Mastiff (Panja)",
    american_pit_bull_terrier = "American Pit Bull Terrier",
    american_staffordshire_terrier = "American Staffordshire Terrier",
    american_staghound = "American Staghound",
    american_toy_terrier = "American Toy Terrier",
    american_water_spaniel = "American Water Spaniel",
    american_white_shepherd = "American White Shepherd",
    anatolian_shepherd_dog = "Anatolian Shepherd Dog",
    andalusian_podenco = "Andalusian Podenco",
    appenzell_mountain_dog = "Appenzell Mountain Dog",
    ariegeois = "Ariegeois",
    argentine_dogo = "Argentine Dogo",
    armenian_gampr = "Armenian Gampr",
    atlas_terrier = "Atlas Terrier",
    australian_bandog = "Australian Bandog",
    australian_bulldog = "Australian Bulldog",
    australian_cattle_dog = "Australian Cattle Dog",
    australian_cobberdog = "Australian Cobberdog",
    australian_kelpie = "Australian Kelpie",
    australian_koolie = "Australian Koolie",
    australian_labradoodle = "Australian Labradoodle",
    australian_shepherd = "Australian Shepherd",
    australian_stumpy_tail_cattle_dog = "Australian Stumpy Tail Cattle Dog",
    australian_terrier = "Australian Terrier",
    austrian_shorthaired_pinscher = "Austrian Shorthaired Pinscher",
    azawakh = "Azawakh",
    banter_bulldogge = "Banter Bulldogge",
    barbet = "Barbet",
    basenji = "Basenji",
    basset_artesien_normand = "Basset Artesien Normand",
    basset_bleu_de_gascogne = "Basset Bleu de Gascogne",
    basset_fauve_de_bretagne = "Basset Fauve de Bretagne",
    basset_hound = "Basset Hound",
    bavarian_mountain_hound = "Bavarian Mountain Hound",
    beagle = "Beagle",
    beagle_harrier = "Beagle Harrier",
    bearded_collie = "Bearded Collie",
    beauceron = "Beauceron",
    bedlington_terrier = "Bedlington Terrier",
    belgian_griffons = "Belgian Griffons",
    belgian_mastiff = "Belgian Mastiff",
    belgian_shepherd_groenendael = "Belgian Shepherd Groenendael",
    belgian_shepherd_laekenois = "Belgian Shepherd Laekenois",
    belgian_shepherd_malinois = "Belgian Shepherd Malinois",
    belgian_shepherd_tervuren = "Belgian Shepherd Tervuren",
    belgrade_terrier = "Belgrade Terrier",
    bergamasco = "Bergamasco",
    berger_blanc_suisse = "Berger Blanc Suisse",
    berger_des_picard = "Berger des Picard",
    berger_des_pyrénées = "Berger des Pyrénées",
    bernese_mountain_dog = "Bernese Mountain Dog",
    bhagyari_kutta = "Bhagyari Kutta",
    bichon_frise = "Bichon Frise",
    bichon_havanais = "Bichon Havanais",
    biewer = "Biewer",
    black_and_tan_coonhound = "Black and Tan Coonhound",
    black_forest_hound = "Black Forest Hound",
    black_mouth_cur = "Black Mouth Cur",
    black_norwegian_elkhound = "Black Norwegian Elkhound",
    black_russian_terrier = "Black Russian Terrier",
    bleus_de_gascogne = "Bleus de Gascogne",
    bloodhound = "Bloodhound",
    blue_heeler = "Blue Heeler",
    blue_lacy = "Blue Lacy",
    blue_picardy_spaniel = "Blue Picardy Spaniel",
    bluetick_coonhound = "Bluetick Coonhound",
    boerboel = "Boerboel",
    bohemian_shepherd = "Bohemian Shepherd",
    bohemian_terrier = "Bohemian Terrier",
    bolognese = "Bolognese",
    bonsai_bulldogge = "Bonsai Bulldogge",
    border_collie = "Border Collie",
    border_terrier = "Border Terrier",
    borzoi = "Borzoi",
    bosnian_herzegovinian_sheepdog = "Bosnian-Herzegovinian Sheepdog - Tornjak",
    boston_terrier = "Boston Terrier",
    bouvier_des_flandres = "Bouvier des Flandres",
    boxer = "Boxer",
    boykin_spaniel = "Boykin Spaniel",
    bracco_italiano = "Bracco Italiano",
    braque_du_bourbonnais = "Braque du Bourbonnais",
    brazilian_terrier = "Brazilian Terrier",
    briard = "Briard",
    brittany_spaniel = "Brittany Spaniel",
    briquet = "Briquet",
    broholmer = "Broholmer",
    brussels_griffon = "Brussels Griffon",
    bukovina_sheepdog = "Bukovina Sheepdog",
    buldogue_campeiro = "Buldogue Campeiro",
    bull_terrier = "Bull Terrier",
    bully_kutta = "Bully Kutta",
    bulldog = "Bulldog",
    bullmastiff = "Bullmastiff",
    cairn_terrier = "Cairn Terrier",
    cajun_squirrel_dog = "Cajun Squirrel Dog",
    cambodian_razorback_dog = "Cambodian Razorback Dog",
    canaan_dog = "Canaan Dog",
    canadian_eskimo_dog = "Canadian Eskimo Dog",
    canadian_inuit_dog = "Canadian Inuit Dog",
    canary_dog = "Canary Dog",
    cane_corso_italiano = "Cane Corso Italiano",
    canis_panther = "Canis Panther",
    canoe_dog = "Canoe Dog",
    cão_da_serra_da_estrela = "Cão da Serra da Estrela",
    cão_da_serra_de_aires = "Cão da Serra de Aires",
    cão_de_castro_laboreiro = "Cão de Castro Laboreiro",
    cão_de_fila_de_são_miguel = "Cão de Fila de São Miguel",
    caravan_hound = "Caravan Hound",
    carlin_pinscher = "Carlin Pinscher",
    carolina_dog = "Carolina Dog",
    carpathian_sheepdog = "Carpathian Sheepdog",
    catahoula_leopard_dog = "Catahoula Leopard Dog",
    catalan_sheepdog = "Catalan Sheepdog",
    cardigan_welsh_corgi = "Cardigan Welsh Corgi",
    caucasian_ovtcharka = "Caucasian Ovtcharka",
    cavalier_king_charles_spaniel = "Cavalier King Charles Spaniel",
    central_asian_ovtcharka = "Central Asian Ovtcharka",
    cesky_fousek = "Cesky Fousek",
    cesky_terrier = "Cesky Terrier",
    chart_polski = "Chart Polski",
    chesapeake_bay_retriever = "Chesapeake Bay Retriever",
    chien_dartois = "Chien D'Artois",
    chien_de_latlas = "Chien De L' Atlas",
    chihuahua = "Chihuahua",
    chin = "Chin",
    chinese_chongqing_dog = "Chinese Chongqing Dog",
    chinese_crested = "Chinese Crested",
    chinese_foo_dog = "Chinese Foo Dog",
    chinese_imperial_dog = "Chinese Imperial Dog",
    chinese_sharpei = "Chinese Shar-Pei",
    chinook = "Chinook",
    chow_chow = "Chow Chow",
    cierny_sery = "Cierny Sery",
    cirneco_delletna = "Cirneco Dell'Etna",
    clumber_spaniel = "Clumber Spaniel",
    cocker_spaniel = "Cocker Spaniel",
    collie = "Collie (Rough and Smooth)",
    continental_bulldog = "Continental Bulldog",
    continental_toy_spaniel = "Continental Toy Spaniel",
    corgi = "Corgi",
    coton_de_tulear = "Coton de Tulear",
    cretan_hound = "Cretan Hound",
    croatian_sheepdog = "Croatian Sheepdog",
    curly_coated_retriever = "Curly-Coated Retriever",
    czechoslovakian_wolfdog = "Czechoslovakian Wolfdog",
    czesky_terrier = "Czesky Terrier",
    dachshund = "Dachshund",
    dakotah_shepherd = "Dakotah Shepherd",
    dalmatian = "Dalmatian",
    dandie_dinmont_terrier = "Dandie Dinmont Terrier",
    danish_broholmer = "Danish Broholmer",
    danish_swedish_farmdog = "Danish-Swedish Farmdog",
    denmark_feist = "Denmark Feist",
    deutsch_drahthaar_ = "Deutsch Drahthaar ",
    deutscher_wachtelhund = "Deutscher Wachtelhund",
    dingo = "Dingo",
    doberman_pinscher = "Doberman Pinscher",
    dogo_argentino = "Dogo Argentino",
    dogue_de_bordeaux = "Dogue de Bordeaux",
    dorset_olde_tyme_bulldogge = "Dorset Olde Tyme Bulldogge",
    drentse_patrijshond = "Drentse Patrijshond",
    drever = "Drever",
    dutch_shepherd_dog = "Dutch Shepherd Dog",
    dutch_smoushond = "Dutch Smoushond",
    east_european_shepherd = "East-European Shepherd",
    east_siberian_laika = "East Siberian Laika",
    english_bulldog = "English Bulldog",
    english_cocker_spaniel = "English Cocker Spaniel",
    english_coonhound = "English Coonhound",
    english_foxhound = "English Foxhound",
    english_pointer = "English Pointer",
    english_setter = "English Setter",
    english_shepherd = "English Shepherd",
    english_springer_spaniel = "English Springer Spaniel",
    english_toy_spaniel = "English Toy Spaniel",
    entlebucher_sennenhund = "Entlebucher Sennenhund",
    estonian_hound = "Estonian Hound",
    estrela_mountain_dog = "Estrela Mountain Dog",
    eurasier = "Eurasier",
    farm_collie = "Farm Collie",
    fauve_de_bretagne = "Fauve de Bretagne",
    feist = "Feist",
    field_spaniel = "Field Spaniel",
    fila_brasileiro = "Fila Brasileiro",
    finnish_hound = "Finnish Hound",
    finnish_lapphund = "Finnish Lapphund",
    finnish_spitz = "Finnish Spitz",
    flat_coated_retriever = "Flat-Coated Retriever",
    foxhound = "Foxhound",
    fox_terrier = "Fox Terrier",
    french_brittany_spaniel = "French Brittany Spaniel",
    french_bulldog = "French Bulldog",
    french_mastiff = "French Mastiff",
    french_pointing_dog = "French Pointing Dog",
    french_spaniel = "French Spaniel",
    galgo_español = "Galgo Español",
    german_hunt_terrier = "German Hunt Terrier",
    german_longhaired_pointer = "German Longhaired Pointer",
    german_pinscher = "German Pinscher",
    german_sheeppoodle = "German Sheeppoodle",
    german_shepherd_dog = "German Shepherd Dog",
    german_shorthaired_pointer = "German Shorthaired Pointer",
    german_spitz = "German Spitz",
    german_spitz_giant = "German Spitz Giant",
    german_spitz_medium = "German Spitz Medium",
    german_spitz_small = "German Spitz Small",
    german_wirehaired_pointer = "German Wirehaired Pointer",
    giant_maso_mastiff = "Giant Maso Mastiff",
    giant_schnauzer = "Giant Schnauzer",
    glen_of_imaal_terrier = "Glen of Imaal Terrier",
    golddust_yorkshire_terrier = "Golddust Yorkshire Terrier",
    golden_retriever = "Golden Retriever",
    gordon_setter = "Gordon Setter",
    grand_griffon_vendeen_ = "Grand Griffon Vendeen ",
    great_dane = "Great Dane",
    great_pyrenees = "Great Pyrenees",
    greater_swiss_mountain_dog = "Greater Swiss Mountain Dog",
    greek_hound = "Greek Hound",
    greek_sheepdog = "Greek Sheepdog",
    greenland_dog = "Greenland Dog",
    greyhound = "Greyhound",
    griffon_nivernais = "Griffon Nivernais",
    groenendael = "Groenendael",
    grosser_münsterlander_vorstehhund = "Grosser Münsterlander Vorstehhund",
    guatemalan_bull_terrier = "Guatemalan Bull Terrier",
    hairless_khala = "Hairless Khala",
    halden_hound = "Halden Hound",
    hamilton_hound = "Hamilton Hound",
    harrier = "Harrier",
    havanese = "Havanese",
    hawaiian_poi_dog = "Hawaiian Poi Dog",
    hellenikos_ichnilatis = "Hellenikos Ichnilatis",
    himalayan_sheepdog = "Himalayan Sheepdog",
    hokkaido_dog = "Hokkaido Dog",
    hovawart = "Hovawart",
    hungarian_kuvasz = "Hungarian Kuvasz",
    hungarian_puli = "Hungarian Puli",
    hungarian_wire_haired_pointing_dog = "Hungarian Wire-haired Pointing Dog",
    husky = "Husky",
    ibizan_hound = "Ibizan Hound",
    icelandic_sheepdog = "Icelandic Sheepdog",
    inca_hairless_dog = "Inca Hairless Dog",
    irish_red_and_white_setter = "Irish Red and White Setter",
    irish_setter = "Irish Setter",
    irish_staffordshire_bull_terrier = "Irish Staffordshire Bull Terrier",
    irish_terrier = "Irish Terrier",
    irish_water_spaniel = "Irish Water Spaniel",
    irish_wolfhound = "Irish Wolfhound",
    istrian_shorthaired_hound = "Istrian Shorthaired Hound",
    italian_greyhound = "Italian Greyhound",
    italian_hound = "Italian Hound",
    italian_spinoni = "Italian Spinoni",
    jack_russell_terrier = "Jack Russell Terrier",
    japanese_spaniel = "Japanese Spaniel (Chin)",
    japanese_spitz = "Japanese Spitz",
    japanese_terrier = "Japanese Terrier",
    jindo = "Jindo",
    kai_dog = "Kai Dog",
    kangal_dog = "Kangal Dog",
    kangaroo_dog = "Kangaroo Dog",
    karabash = "Karabash",
    karakachan = "Karakachan",
    karelian_bear_dog = "Karelian Bear Dog",
    karst_shepherd = "Karst Shepherd",
    keeshond = "Keeshond",
    kelb_tal_fenek = "Kelb Tal-Fenek",
    kemmer_feist = "Kemmer Feist",
    kerry_blue_terrier = "Kerry Blue Terrier",
    king_charles_spaniel = "King Charles Spaniel",
    king_shepherd = "King Shepherd",
    kishu_ken = "Kishu Ken",
    klein_poodle = "Klein Poodle",
    kokoni = "Kokoni",
    komondor = "Komondor",
    kooikerhondje = "Kooikerhondje",
    koolie = "Koolie",
    korean_dosa_mastiff = "Korean Dosa Mastiff",
    krasky_ovcar = "Krasky Ovcar",
    kugsha_dog = "Kugsha Dog",
    kunming_dog = "Kunming Dog",
    kuvasz = "Kuvasz",
    kyi_leo = "Kyi-Leo®",
    labrador_husky = "Labrador Husky",
    labrador_retriever = "Labrador Retriever",
    lagotto_romagnolo = "Lagotto Romagnolo",
    lakeland_terrier = "Lakeland Terrier",
    lakota_mastino = "Lakota Mastino",
    lancashire_heeler = "Lancashire Heeler",
    landseer = "Landseer",
    lapinporokoira = "Lapinporokoira",
    lapphunds = "Lapphunds",
    large_münsterländer = "Large Münsterländer",
    larson_lakeview_bulldogge = "Larson Lakeview Bulldogge",
    latvian_hound = "Latvian Hound",
    leavitt_bulldog = "Leavitt Bulldog",
    leonberger = "Leonberger",
    lhasa_apso = "Lhasa Apso",
    lithuanian_hound = "Lithuanian Hound",
    llewellin_setter = "Llewellin Setter",
    louisiana_catahoula_leopard_dog = "Louisiana Catahoula Leopard Dog",
    löwchen = "Löwchen (Little Lion Dog)",
    lucas_terrier = "Lucas Terrier",
    lundehund = "Lundehund",
    majestic_tree_hound = "Majestic Tree Hound",
    maltese = "Maltese",
    mammut_bulldog = "Mammut Bulldog",
    manchester_terrier = "Manchester Terrier",
    maremma_sheepdog = "Maremma Sheepdog",
    markiesje = "Markiesje",
    mastiff = "Mastiff",
    mcnab = "McNab",
    mexican_hairless = "Mexican Hairless",
    mi_ki = "Mi-Ki",
    middle_asian_ovtcharka = "Middle Asian Ovtcharka",
    miniature_american_eskimo = "Miniature American Eskimo",
    miniature_australian_bulldog = "Miniature Australian Bulldog",
    miniature_australian_shepherd = "Miniature Australian Shepherd",
    miniature_bull_terrier = "Miniature Bull Terrier",
    miniature_fox_terrier = "Miniature Fox Terrier",
    miniature_pinscher = "Miniature Pinscher",
    miniature_poodle = "Miniature Poodle",
    miniature_schnauzer = "Miniature Schnauzer",
    miniature_sharpei = "Miniature Shar-Pei",
    mioritic_sheepdog = "Mioritic Sheepdog",
    moscow_toy_terrier = "Moscow Toy Terrier",
    moscow_vodolaz = "Moscow Vodolaz",
    moscow_watchdog = "Moscow Watchdog",
    mountain_cur = "Mountain Cur",
    mountain_feist = "Mountain Feist",
    mountain_view_cur = "Mountain View Cur",
    moyen_poodle = "Moyen Poodle",
    mucuchies = "Mucuchies",
    mudi = "Mudi",
    munsterlander = "Munsterlander",
    native_american_indian_dog = "Native American Indian Dog",
    neapolitan_mastiff = "Neapolitan Mastiff",
    nebolish_mastiff = "Nebolish Mastiff",
    new_guinea_singing_dog = "New Guinea Singing Dog",
    new_zealand_heading_dog = "New Zealand Heading Dog",
    new_zealand_huntaway = "New Zealand Huntaway",
    newfoundland = "Newfoundland",
    norrbottenspets = "Norrbottenspets",
    norfolk_terrier = "Norfolk Terrier",
    north_american_miniature_australian_shepherd = "North American Miniature Australian Shepherd",
    northern_inuit_dog = "Northern Inuit Dog",
    norwegian_buhund = "Norwegian Buhund",
    norwegian_elkhound = "Norwegian Elkhound",
    norwegian_lundehund = "Norwegian Lundehund",
    norwich_terrier = "Norwich Terrier",
    nova_scotia_ducktolling_retriever = "Nova Scotia Duck-Tolling Retriever",
    ol_southern_catchdog = "Ol' Southern Catchdog",
    old_danish_chicken_dog = "Old Danish Chicken Dog",
    old_english_mastiff = "Old English Mastiff",
    old_english_sheepdog = "Old English Sheepdog (Bobtail)",
    olde_boston_bulldogge = "Olde Boston Bulldogge",
    olde_english_bulldogge = "Olde English Bulldogge",
    olde_victorian_bulldogge = "Olde Victorian Bulldogge",
    original_english_bulldogge = "Original English Bulldogge",
    original_mountain_cur = "Original Mountain Cur",
    otterhound = "Otterhound",
    otto_bulldog = "Otto Bulldog",
    owczarek_podhalanski = "Owczarek Podhalanski",
    pakistani_bull_dog = "Pakistani Bull Dog (Gull Dong) ",
    pakistani_bull_terrier = "Pakistani Bull Terrier (Pakistani Gull Terr)",
    pakistani_mastiff = "Pakistani Mastiff (Pakisani Bully Kutta)",
    pakistani_shepherd_dog = "Pakistani Shepherd Dog (Bhagyari Kutta)",
    panda_shepherd = "Panda Shepherd",
    papillon = "Papillon",
    parson_russell_terrier = "Parson Russell Terrier",
    patterdale_terrier = "Patterdale Terrier",
    pekingese = "Pekingese",
    pembroke_welsh_corgi = "Pembroke Welsh Corgi",
    perdiguero_de_burgos = "Perdiguero de Burgos",
    perro_cimarron = "Perro Cimarron",
    perro_de_presa_canario = "Perro de Presa Canario",
    perro_de_presa_mallorquin = "Perro de Presa Mallorquin",
    perro_dogo_mallorquin = "Perro Dogo Mallorquin",
    perro_ratonero_andaluz = "Perro Ratonero Andaluz",
    peruvian_inca_orchid = "Peruvian Inca Orchid (PIO)",
    petit_basset_griffon_vendeen = "Petit Basset Griffon Vendeen",
    petit_bleu_de_gascogne = "Petit Bleu de Gascogne",
    petit_brabancon = "Petit Brabancon",
    pharaoh_hound = "Pharaoh Hound",
    phu_quoc_ridgeback_dog = "Phu Quoc Ridgeback Dog",
    pit_bull_terrier = "Pit Bull Terrier",
    plott_hound = "Plott Hound",
    plummer_hound = "Plummer Hound",
    pocket_beagle = "Pocket Beagle",
    podenco_ibicenco = "Podenco Ibicenco",
    pointer = "Pointer",
    polish_hound = "Polish Hound",
    polish_tatra_sheepdog = "Polish Tatra Sheepdog",
    polish_lowland_sheepdog = "Polish Lowland Sheepdog",
    pomeranian = "Pomeranian",
    poodle = "Poodle",
    porcelaine = "Porcelaine",
    portuguese_hound = "Portuguese Hound",
    portuguese_pointer = "Portuguese Pointer",
    portuguese_sheepdog = "Portuguese Sheepdog",
    portuguese_water_dog = "Portuguese Water Dog",
    posavac_hound = "Posavac Hound",
    potsdam_greyhound = "Potsdam Greyhound",
    prazsky_krysavik = "Prazsky Krysavik",
    presa_canario = "Presa Canario",
    pudelpointer = "Pudelpointer",
    pug = "Pug",
    puli = "Puli (Pulik)",
    pumi = "Pumi",
    pyrenean_mastiff = "Pyrenean Mastiff",
    pyrenean_mountain_dog = "Pyrenean Mountain Dog",
    pyrenean_shepherd = "Pyrenean Shepherd",
    queensland_heeler = "Queensland Heeler",
    queen_elizabeth_pocket_beagle = "Queen Elizabeth Pocket Beagle",
    rafeiro_do_alentejo = "Rafeiro do Alentejo",
    rajapalayam = "Rajapalayam",
    rampur_greyhound = "Rampur Greyhound",
    rat_terrier = "Rat Terrier",
    redbone_coonhound = "Redbone Coonhound",
    red_tiger_bulldog = "Red-Tiger Bulldog",
    rhodesian_ridgeback = "Rhodesian Ridgeback",
    roman_rottweiler = "Roman Rottweiler",
    rottweiler = "Rottweiler",
    rough_collie = "Rough Collie",
    rumanian_sheepdog = "Rumanian Sheepdog",
    russian_bear_schnauzer = "Russian Bear Schnauzer",
    russian_hound = "Russian Hound",
    russian_spaniel = "Russian Spaniel",
    russian_toy = "Russian Toy",
    russian_tsvetnaya_bolonka = "Russian Tsvetnaya Bolonka",
    russian_wolfhound = "Russian Wolfhound",
    russo_european_laika = "Russo-European Laika",
    saarlooswolfhond = "Saarlooswolfhond",
    sabueso_español = "Sabueso Español",
    sage_ashayeri = "Sage Ashayeri",
    saint_bernard = "Saint Bernard",
    saluki = "Saluki",
    samoyed = "Samoyed",
    sarplaninac = "Sarplaninac",
    schapendoes = "Schapendoes",
    schipperke = "Schipperke",
    schnauzer = "Schnauzer",
    scotch_collie = "Scotch Collie",
    scottish_deerhound = "Scottish Deerhound",
    scottish_terrier = "Scottish Terrier (Scottie)",
    sealydale_terrier = "Sealydale Terrier",
    sealyham_terrier = "Sealyham Terrier",
    segugio_italiano = "Segugio Italiano",
    sharpei = "Shar-Pei",
    shetland_sheepdog = "Shetland Sheepdog (Sheltie)",
    shiba_inu = "Shiba Inu",
    shih_tzu = "Shih Tzu",
    shikoku = "Shikoku",
    shiloh_shepherd = "Shiloh Shepherd",
    siberian_husky = "Siberian Husky",
    siberian_laika = "Siberian Laika",
    silken_windhound = "Silken Windhound",
    silky_terrier = "Silky Terrier",
    simaku = "Simaku",
    skye_terrier = "Skye Terrier",
    sloughi = "Sloughi",
    slovakian_hound = "Slovakian Hound",
    slovakian_rough_haired_pointer = "Slovakian Rough Haired Pointer",
    slovensky_cuvac = "Slovensky Cuvac",
    slovensky_hrubosrsty_stavac = "Slovensky Hrubosrsty Stavac",
    slovensky_kopov = "Slovensky Kopov",
    smalandsstovare = "Smalandsstovare",
    small_bernese_hound = "Small Bernese Hound",
    small_greek_domestic_dog = "Small Greek Domestic Dog",
    small_jura_hound = "Small Jura Hound",
    small_lucerne_hound = "Small Lucerne Hound",
    small_munsterlander = "Small Munsterlander",
    small_schwyz_hound = "Small Schwyz Hound",
    small_swiss_hound = "Small Swiss Hound",
    smooth_collie = "Smooth Collie",
    smooth_fox_terrier = "Smooth Fox Terrier",
    soft_coated_wheaten_terrier = "Soft Coated Wheaten Terrier",
    south_russian_ovtcharka = "South Russian Ovtcharka",
    spanish_bulldog = "Spanish Bulldog",
    spanish_mastiff = "Spanish Mastiff",
    spanish_water_dog = "Spanish Water Dog",
    spinone_italiano = "Spinone Italiano",
    springer_spaniel = "Springer Spaniel",
    stabyhoun = "Stabyhoun",
    staffordshire_bull_terrier = "Staffordshire Bull Terrier",
    standard_american_eskimo = "Standard American Eskimo",
    standard_poodle = "Standard Poodle",
    standard_schnauzer = "Standard Schnauzer",
    stephens_stock_mountain_cur = "Stephens' Stock Mountain Cur",
    sussex_spaniel = "Sussex Spaniel",
    swedish_vallhund = "Swedish Vallhund",
    swiss_laufhund_ = "Swiss Laufhund ",
    swiss_shorthaired_pinscher = "Swiss Shorthaired Pinscher",
    taigan = "Taigan",
    tamaskan_dog = "Tamaskan Dog",
    teacup_poodle = "Teacup Poodle",
    teddy_roosevelt_terrier = "Teddy Roosevelt Terrier",
    telomian = "Telomian",
    tenterfield_terrier = "Tenterfield Terrier",
    tepeizeuintli = "Tepeizeuintli",
    thai_bangkaew_dog = "Thai Bangkaew Dog",
    thai_ridgeback = "Thai Ridgeback",
    the_carolina_dog = "The Carolina Dog",
    tibetan_mastiff = "Tibetan Mastiff",
    tibetan_spaniel = "Tibetan Spaniel",
    tibetan_terrier = "Tibetan Terrier",
    titan_bull_dogge = "Titan Bull-Dogge",
    titan_terrier = "Titan Terrier",
    tornjak = "Tornjak",
    tosa_inu = "Tosa Inu",
    toy_american_eskimo = "Toy American Eskimo",
    toy_fox_terrier = "Toy Fox Terrier",
    toy_manchester_terrier = "Toy Manchester Terrier",
    toy_poodle = "Toy Poodle",
    transylvanian_hound = "Transylvanian Hound",
    treeing_tennessee_brindle = "Treeing Tennessee Brindle",
    treeing_walker_coonhound = "Treeing Walker Coonhound",
    tuareg_sloughi = "Tuareg Sloughi",
    utonagan = "Utonagan",
    victorian_bulldog = "Victorian Bulldog",
    villano_de_las_encartaciones = "Villano de Las Encartaciones",
    vizsla = "Vizsla",
    vucciriscu = "Vucciriscu",
    weimaraner = "Weimaraner",
    welsh_corgi = "Welsh Corgi",
    welsh_sheepdog = "Welsh Sheepdog",
    welsh_springer_spaniel = "Welsh Springer Spaniel",
    welsh_terrier = "Welsh Terrier",
    west_highland_white_terrier = "West Highland White Terrier (Westie)",
    west_siberian_laika = "West Siberian Laika",
    wetterhoun = "Wetterhoun",
    wheaten_terrier = "Wheaten Terrier",
    whippet = "Whippet",
    white_english_bulldog = "White English Bulldog",
    white_german_shepherd = "White German Shepherd",
    white_swiss_shepherd = "White Swiss Shepherd",
    wire_fox_terrier = "Wire Fox Terrier",
    wirehaired_pointing_griffon = "Wirehaired Pointing Griffon",
    wirehaired_vizsla = "Wirehaired Vizsla",
    xoloitzcuintle = "Xoloitzcuintle",
    yorkshire_terrier = "Yorkshire Terrier",
    yugoslavian_hound = "Yugoslavian Hound"
}

export interface AnimalLicenseAppTab {
    _id: string;
    activeStatus: boolean;
    formIntroText: string;
    formIntroHeader: string;
    tabName: string;
    tabLabel: string;
    tabOrder: number;
}

export interface Citizen {
    _id: string
    uid?: string
    firstName?: string
    lastName?: string
    cellPhone?: string
    workPhone?: string
    address?: Address
    userPreferences?: UserPreferences
    accounts?: SubledgerAccount[]
}

export interface UserPreferences {
    wantsUBEmail: boolean
    wantsUBPaper: boolean
    wantsTXEmail: boolean
    wantsTXPaper: boolean
}

export interface SubledgerAccount {
    accountNumber: string
    accountAccessCode: string
    accountSubledger: string
}

export interface CscClient {
    _id: string;
    account?: string;
    cityLongCode: string;
    cityProductAccess: CityProductAccess;
    clientCode: string;
    logoRef: string;
    cityCurrencyCode: CityCurrencyCodeEnum;
    convenienceFees: ConvenienceFees[];
    paymentGateway: PaymentGatewayEnum;
    paymentGatewayEmailOptions: PaymentGatewayEmailOptions;
    paymentOptions: PaymentOptionsType;
    tabs?: Tab[];
    cityDisplayName?: string;
    styles: CscStyles;
    legacyServiceRequest: string,
    workflowTemplates: {
        AL?: string,
        BL?: string,
        BP?: string,
        SR?: string
    }
}

export interface Tab {
    _id: string;
    activeStatus: boolean;
    formIntroText: string;
    formIntroHeader: string;
    tabName: string;
    tabLabel: string;
    tabOrder: number;
}

export enum CityCurrencyCodeEnum {
    cad = "Canadian Dollars",
    usd = "US Dollars"
}

export interface CityProductAccess {
    AL: boolean;
    BL: boolean;
    SR: boolean;
    UB: boolean;
    TX: boolean;
    CART: boolean;
}

export interface CscStyles {
    background: {
        "background-color": string,
    },
    button: {
        "background-color": string,
        "color": string
    },
    text: {
        "color": string,
    }
}

export interface ConvenienceFees {
    feeType: ConvenienceFeeTypeEnum;
    feeAppliedAmount: number;
}

export enum ConvenienceFeeTypeEnum {
    percentage = "percentage",
    fixed = "fixed"
}

export enum PaymentGatewayEnum {
    STRIPE = "Stripe Payments"
}

export interface PaymentGatewayEmailOptions {
    emailBody: string;
    emailSubject: string;
    emailFromAddress: string;
    emailFromName: string;
}

export interface PaymentOptionsType {
    AL: PaymentOptionsTypesEnum;
    BL: PaymentOptionsTypesEnum;
    SR: PaymentOptionsTypesEnum;
    UB: PaymentOptionsTypesEnum;
    TX: PaymentOptionsTypesEnum;
}

export enum PaymentOptionsTypesEnum {
    paymentsNotEnabled = "Payments are not enabled for this module",
    account = "accountFullPartial [insert description here]",
    invoicePartial = "cartFullPartial [insert description here]",
    invoiceFull = "cartFull [insert description here]"
}

export interface Invoice {
    _id: string;
    globalAccountNumber: string[];
    invoiceAmountDue: number;
    invoiceAmountPaid: number;
    invoiceCreatedAt: Date;
    invoiceData: {
        invoiceAccessCode: string,
        invoiceAccountNumber: string
    };
    invoiceDescription: string;
    invoiceDueAtDate: Date;
    invoiceSubledger: string;
    isPdfAvailable: boolean;

    invoicePaidAt?: Array<Date>;
    details?: any // (per details file);
    consumption?: any // (per consumption file);
    readings?: any // (per reading file);
    invoiceMasterReference?: string; // firebase document ref
}

export interface InvoiceContact {
    invoiceContactName: string;
    invoiceContactEmail: string;
    invoiceContactPhoneNumber: string;
}

export interface InvoicePayment {
    invoiceAmountPaid: number
    invoicePaidAt: Date
}

export interface StripeAuthConfig {
    clientId: string;
    oauthUrl: string;
    redirectUri: string;
    responseType: string;
    scope: string;
    stripeLanding: string;
}

export interface payment {
    _id: string
    paymentDate: Date
    paymentAmount: number
    paymentAccountNumber: string
    paymentSubledger: string
    paymentUser: string
    paymentProcessingFeeCost: number
    paymentConvenienceFeeRevenue: number
}

export enum PaymentOption {
    cartFull = "cartFull",
    cartFullPartial = "cartFullPartial",
    accountFullPartial = "accountFullPartial",
    cartFullPartialTwoColumns = "cartFullPartialTwoColumns",
    paymentsNotEnabled = "paymentsNotEnabled"
}

export interface ConvenienceFee {
    feeType: string;
    feeAppliedAmount: number;
}

export interface PdfMetaData {
    _id: string;
    pdfAccessCode: string;
    pdfType: string;
    pdfAccountNumber: string;
    pdfClient: string;
    pdfDocumentAmount: string;
    pdfYear: string;
    pdfPeriodCode: string;
    pdfGlobalAccountNumber: string;
    pdfDate: string;
    pdfDocType: string;
}

export interface PropertyTaxAccount {
    _id: string;
    txRollNumber: string;
    txAccessCode: string;
    txDateAdded: string;
    txDateChangedOwners: string;
}

export interface PropertyTaxAppTab {
    _id: string;
    activeStatus: boolean;
    formIntroText: string;
    formIntroHeader: string;
    tabName: string;
    tabLabel: string;
    tabOrder: number;
}

export interface PropertyTaxesSettings {
    _id: string;
    paymentNotificationEmailContent: string;
    paymentNotificationEmailSubject: string;
    notificationEmailFromAddress: string;
}

export interface Receipt {
    _id: string;
    receiptAmount: number;
    receiptCartItems: Array<CartItem>;
    receiptPaymentIntentId: string;
    receiptProcessingFeeCost: number;
    receiptConvenienceFeeRevenue: number;
    receiptPaidAt: any;
    receiptSubtotal: number;
    receiptEmail?: string
}

export interface ShoppingCart {
    shoppingCartSubtotalAmount: number;
    shoppingCartTotalAmount: number;
    shoppingCartTotalConvenienceFees: number;
    shoppingCartItemsCount: number;
    shoppingCartItems: Array<CartItem>;
}

export interface CartItem {
    cartItemAmountToPay: number;
    cartItemInvoiceIdToPay: string;
    cartItemDescription?: string;
    cartItemAccountNumber?: string;
    cartItemSubledger?: string;
    cartItemInvoiceCreatedAt?: any;
}

export interface FirebaseUser {
    _id: string,
    uid: string,
    userCityCode: string,
    userEmail: string,
    userFirstName: string,
    userIsDisabled: boolean,
    userLastName: string,
    userType: string,
    userPreferences: UserPreferences
};

export interface UserPreferences {
    wantsUBEmail: boolean,
    wantsUBPaper: boolean,
    wantsTXEmail: boolean,
    wantsTXPaper: boolean,
}

export interface Address {
    suite: string,
    house: string,
    address1: string,
    address2: string,
    city: string,
    provOrState: string,
    postOrZip?: string,
    country?: string,
    lat?: number,
    long?: number,
    propertyIdentifier?: string,
    otherPropertyIdentifiers?: string,
    indexedAddress?: string
}

export interface ClientCity {
    cityDisplayName: string,
    cityLongCode: string,
    isActive: boolean
}

export interface DocumentDefinitionPdfMake {
    pageSize: string;
    pageOrientation: string;
    pageMargins: Array<number>;
    content: any;
    header?: any;
    styles?: any;
    footer?: any;
    defaultStyle?: {
        fontSize?: number;
        font?: string,
    };
}