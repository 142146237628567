import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UbState } from './ub.reducer';


const getUbFeatureState = createFeatureSelector<UbState>('ubState');

/**
 * utility billing selectors
 */

 export const getUbTabs = createSelector(
    getUbFeatureState,
    state => state.ubTabs
 );

 export const getUbInvoices = createSelector(
    getUbFeatureState,
    state => state.ubInvoices
 );

 export const getSelectedAccount = createSelector(
   getUbFeatureState,
   state => state.ubSelectedAccount
);

export const getSelectedInvoice = createSelector(
   getUbFeatureState,
   state => state.ubSelectedInvoice
);

export const getSelectedAccountInvoices = createSelector(
   getUbFeatureState,
   state => state.ubSelectedAccountInvoices
);