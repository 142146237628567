<div class="backdrop">

    <h2>Citizen Service Cloud</h2>

    <ng-container *ngIf="!showSpinner">
    <div class="box">
        <form [formGroup]="citySelectForm">
            <mat-form-field>
                <mat-label>Please select a city</mat-label>
                <mat-select formControlName="cityLongCode">
                    <mat-option *ngFor="let city of cityList" [value]="city.cityLongCode">
                        {{city.cityDisplayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <button mat-raised-button (click)="onSubmit()" [disabled]="citySelectForm.invalid">
            Log in to selected city
        </button>
    </div>
    </ng-container>

    <ng-container *ngIf="showSpinner">
        <app-spinner class="spinner"></app-spinner>
    </ng-container>


</div>