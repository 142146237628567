import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppActions, AppSelectors } from 'src/app/global/state';
import { WorkflowInstance } from 'src/app/global/state/models/workflow/workflowInstance';
import { TaskTemplate } from 'src/app/global/state/models/workflow/taskTemplate';

@Component({
  selector: 'app-task-container',
  templateUrl: './task-container.component.html',
  styleUrls: ['./task-container.component.scss']
})

export class TaskContainerComponent implements OnInit {
  availableWorkflowTasks$: Observable<TaskTemplate[]>
  activeInstance$: Observable<WorkflowInstance>;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.availableWorkflowTasks$ = this.store.select(AppSelectors.getAvailableWorkflowTasks);
    this.activeInstance$ = this.store.select(AppSelectors.getActiveWorkflowInstance);
  }

}
