import { createReducer, on } from '@ngrx/store';
import { CartItem, Citizen, ConvenienceFeeTypeEnum, CscClient, FirebaseUser, Receipt, ShoppingCart, SubledgerAccount } from './models/models';
import * as AppActions from './app.actions';
import { WorkflowTemplate, WorkflowInstance, TaskTemplate } from './models/workflow/models';
import { STATE_PROVIDERS } from '@ngrx/store/src/state';

export interface UserState {
    cityInfo: CscClient,
    user: FirebaseUser,
    citizen: Citizen,
    shoppingCart: ShoppingCart,
    receiptToPrint: Receipt,
    account: string,
    workflow: Workflow,
    error: string
}

export interface Workflow {
    activeTemplate?: WorkflowTemplate,
    activeInstance?: WorkflowInstance,
    availableTasks?: TaskTemplate[],
}

const initialCityInfoState: CscClient = {
    _id: '',
    cityLongCode: '',
    cityProductAccess: null,
    clientCode: '',
    logoRef: '',
    cityCurrencyCode: null,
    convenienceFees: null,
    paymentGateway: null,
    paymentGatewayEmailOptions: null,
    paymentOptions: null,
    tabs: null,
    cityDisplayName: '',
    legacyServiceRequest: '',
    styles: {
        background: {
            "background-color": ''
        },
        button: {
            "color": '',
            "background-color": ''
        },
        text: {
            "color": ''
        }
    },
    workflowTemplates: undefined
}

const initialUserSummaryState: FirebaseUser = {
    _id: '',
    uid: '',
    userCityCode: '',
    userEmail: '',
    userFirstName: '',
    userIsDisabled: true,
    userLastName: '',
    userType: '',
    userPreferences: {
        wantsTXEmail: false,
        wantsTXPaper: false,
        wantsUBEmail: false,
        wantsUBPaper: false
    }
}

const initialCitizenState: Citizen = {
    _id: "",
    uid: "",
    firstName: "",
    lastName: "",
    cellPhone: "",
    workPhone: "",
    address: null,
    userPreferences: null,
    accounts: null,
}

const initialShoppingCartState: ShoppingCart = {
    shoppingCartItems: [],
    shoppingCartItemsCount: 0,
    shoppingCartSubtotalAmount: 0.00,
    shoppingCartTotalAmount: 0.00,
    shoppingCartTotalConvenienceFees: 0.00,
}

export const initialState: UserState = {
    cityInfo: initialCityInfoState,
    user: initialUserSummaryState,
    citizen: initialCitizenState,
    shoppingCart: initialShoppingCartState,
    workflow: {
        availableTasks: [],
        activeInstance: undefined,
        activeTemplate: undefined
    },
    receiptToPrint: null,
    account: '',
    error: '',
}

export const appReducer = createReducer<UserState>(
    initialState,
    //#region CITY CONTEXT / ACCOUNT
    on(AppActions.loadCityContextSuccess, (state, action): UserState => {
        console.log("action.city.cityLongCode", action.city.cityLongCode);
        localStorage.setItem("city", action.city.cityLongCode.toUpperCase());
        localStorage.setItem("code", action.city.clientCode.toUpperCase());
        localStorage.setItem("cart", '');
        return {
            ...state,
            cityInfo: action.city
        }
    }),
    on(AppActions.loadCityContextFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    on(AppActions.loadCityAccountSuccess, (state, action): UserState => {
        const account = action.account["stripeAccountId"] as string;
        return {
            ...state,
            account: account
        }
    }),
    on(AppActions.loadCityAccountFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    //#endregion

    //#region USER / CITIZEN
    on(AppActions.loginSuccess, (state): UserState => {
        return {
            ...state,
        }
    }),
    on(AppActions.clearUser, (state): UserState => {
        return {
            ...state,
            user: initialUserSummaryState
        }
    }),
    on(AppActions.loadFirebaseUserSuccess, (state, action): UserState => {
        return {
            ...state,
            user: action.user
        }
    }),
    on(AppActions.loadFirebaseUserFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    on(AppActions.loadCitizenSuccess, (state, action): UserState => {
        return {
            ...state,
            citizen: action.citizen
        }
    }),
    on(AppActions.loadCitizenFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    on(AppActions.updateUserPreferencesInFirebaseSuccess, (state, action): UserState => {
        return {
            ...state, //TODO
        }
    }),
    on(AppActions.updateUserPreferencesInFirebaseFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    //#endregion

    on(AppActions.selectReceiptToPrint, (state, action): UserState => {
        return {
            ...state,
            receiptToPrint: action.receipt
        }
    }),

    //#region SHOPPING CART
    // shopping cart reducers here
    on(AppActions.addItemToShoppingCart, (state, action): UserState => {
        let cartItems = [...state.shoppingCart.shoppingCartItems];
        cartItems.push(action.item);
        let subtotal = 0;
        cartItems.forEach(item => subtotal += item.cartItemAmountToPay);
        // calculate the fees
        let total = subtotal;
        let fees = 0;
        let convenienceFeeArray = state.cityInfo.convenienceFees;
        console.log(convenienceFeeArray);
        for (let i = 0; i < convenienceFeeArray.length; i++) {
            const convenienceFee = convenienceFeeArray[i];
            // Add on the convenience fee
            switch (convenienceFee.feeType) {
                case ConvenienceFeeTypeEnum.fixed:
                    total += Number(convenienceFee.feeAppliedAmount);
                    break;
                case ConvenienceFeeTypeEnum.percentage:
                    total *= 1 / (1 - Number(convenienceFee.feeAppliedAmount));
                    break;
                default:
                    console.log("nothing matched!")
            }
        };
        total = Math.ceil(total);
        fees = total - subtotal;
        // create new shopping cart object
        const updatedCart = {
            shoppingCartSubtotalAmount: subtotal,
            shoppingCartTotalAmount: subtotal + fees,
            shoppingCartTotalConvenienceFees: fees,
            shoppingCartItemsCount: cartItems.length,
            shoppingCartItems: cartItems
        };
        /**
         * return state
         */
        return {
            ...state,
            shoppingCart: updatedCart
        }
    }),
    on(AppActions.removeItemFromShoppingCart, (state, action): UserState => {
        let cartItems = [...state.shoppingCart.shoppingCartItems];
        const removeIndex = cartItems.findIndex(item => item.cartItemInvoiceIdToPay == action.item.cartItemInvoiceIdToPay);
        cartItems.splice(removeIndex, 1);
        let subtotal = 0;
        cartItems.forEach(item => subtotal += item.cartItemAmountToPay);
        // calculate the fees
        let total = subtotal;
        let fees = 0;
        let convenienceFeeArray = state.cityInfo.convenienceFees;
        console.log(convenienceFeeArray);
        for (let i = 0; i < convenienceFeeArray.length; i++) {
            const convenienceFee = convenienceFeeArray[i];
            // Add on the convenience fee
            switch (convenienceFee.feeType) {
                case ConvenienceFeeTypeEnum.fixed:
                    total += Number(convenienceFee.feeAppliedAmount);
                    break;
                case ConvenienceFeeTypeEnum.percentage:
                    total *= 1 / (1 - Number(convenienceFee.feeAppliedAmount));
                    break;
                default:
                    console.log("nothing matched!")
            }
        };
        total = Math.ceil(total);
        fees = total - subtotal;
        // create new shopping cart object
        const updatedCart = {
            shoppingCartSubtotalAmount: subtotal,
            shoppingCartTotalAmount: subtotal + fees,
            shoppingCartTotalConvenienceFees: fees,
            shoppingCartItemsCount: cartItems.length,
            shoppingCartItems: cartItems
        };
        /**
         * return state
         */
        return {
            ...state,
            shoppingCart: updatedCart
        }
    }),
    on(AppActions.clearShoppingCart, (state): UserState => {
        return {
            ...state,
            shoppingCart: initialShoppingCartState
        }
    }),
    //#endregion

    //#region STRIPE
    on(AppActions.beginStripeCheckout, (state): UserState => {
        return {
            ...state
        }
    }),
    on(AppActions.completeStripeCheckout, (state): UserState => {
        return {
            ...state
        }
    }),
    //#endregion

    //#region ADD ACCOUNT
    // add account reducers
    // on(AppActions.addAccountSuccess, (state, action): UserState => {
    //     let accounts = [...state.citizen.accounts];
    //     let newAccount: SubledgerAccount = {
    //         accountNumber: action.account.accountNumber,
    //         accountAccessCode: action.account.accountAccessCode,
    //         accountSubledger: action.account.accountType,
    //         accountDateAdded: new Date,
    //     }
    //     accounts.push(newAccount);
    //     let updatedCitizen = {...state.citizen};
    //     updatedCitizen.accounts = accounts;
    //     return {
    //         ...state,
    //         citizen: updatedCitizen
    //     }
    // }),
    on(AppActions.addAccountFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    //#endregion

    on(AppActions.clearError, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),

    //#region CLEAR WORKFLOW STATE
    on(AppActions.clearWorkflowState, (state, action): UserState => {
        return {
            ...state,
            workflow: {
                availableTasks: [],
                activeInstance: undefined,
                activeTemplate: undefined
            }        
        }
    }),
    //#endregion

    //#region WORKFLOW TEMPLATE
    on(AppActions.loadWorkflowTemplateSuccess, (state, action): UserState => {
        let workflow: Workflow = state.workflow;
        workflow = { ...workflow, activeTemplate: action.workflowTemplate }
        return {
            ...state,
            workflow: workflow
        }
    }),
    on(AppActions.loadWorkflowTemplateFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    //#endregion

    //#region WORKFLOW INSTANCE CREATION AND RETRIEVAL
    on(AppActions.createWorkflowInstanceFromTemplateIdSuccess, (state, action): UserState => {
        let workflow: Workflow = state.workflow;
        workflow = { ...workflow, activeInstance: action.workflowInstance, availableTasks: action.availableTasks };
        return {
            ...state,
            workflow: workflow
        }
    }),
    on(AppActions.createWorkflowInstanceFromTemplateIdFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    on(AppActions.loadWorkflowInstanceSuccess, (state, action): UserState => {
        let workflow: Workflow = state.workflow;
        workflow = { ...workflow, activeInstance: action.workflowInstance, availableTasks: action.availableTasks };
        return {
            ...state,
            workflow: workflow
        }
    }),
    on(AppActions.loadWorkflowInstanceFailure, (state, action): UserState => {
        return {
            ...state,
            error: action.error
        }
    }),
    //#endregion

    //#region FORM SUBMISSION REDUCERS
    on(AppActions.formTaskSubmissionSuccess, (state, action): UserState => {
        let workflow: Workflow = state.workflow;
        console.log("availableTasks", action.availableTasks);

        workflow = {
            ...workflow,
            activeInstance: action.activeInstance,
            availableTasks: action.availableTasks
        };
        return {
            ...state,
            workflow: workflow
        }
    }),
    //#endregion


)