import { createAction, props } from '@ngrx/store';
import { CartItem, Invoice, Receipt, SubledgerAccount, Tab } from 'src/app/global/state/models/models';



/**
 * page actions below - actions that are dispatched from pages
 */
export const loadUtilityBillingTabs = createAction(
    '[UB] initiate - load utility billing tabs',
    props<{ cityLongCode: string }>()
);

export const loadUtilityBillsForCitizen = createAction(
    '[UB] initiate - load utility bills for citizen',
    props<{ cityLongCode: string }>()
);

export const selectUbAccount = createAction(
    '[UB] select a UB account',
    props<{ account: SubledgerAccount }>()
);

export const selectUbInvoice = createAction(
    '[UB] select a UB invoice',
    props<{ invoice: Invoice }>()
);

/**
 * calculation actions below - actions that require complex calculations to be performed
 */

export const updateUtilityBillsAfterSuccessfulPayment = createAction(
    '[cart] - update UB invoices after successful payment',
    props<{invoices: CartItem[], receipts: CartItem[]}>()
)

 /**
 * api actions below - actions that require use of external endpoints
 */
export const loadUtilityBillingTabsSuccess = createAction(
    '[UB] success - load utility billing tabs',
    props<{ tabs: Tab[] }>()
)

export const loadUtilityBillingTabsFailure = createAction(
    '[UB] failure - load utility billing tabs',
    props<{ error: string }>()
)

export const loadUtilityBillsForCitizenSuccess = createAction(
    '[UB] success - load utility bills for citizen',
    props<{ invoices: Invoice[] }>()
)

export const loadUtilityBillsForCitizenFailure = createAction(
    '[UB] failure - load utility bills for citizen',
    props<{ error: string }>()
)