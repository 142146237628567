import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ContextService } from 'src/app/global/services/context.service';
import { ReceiptPrintService } from 'src/app/global/services/receipt-print.service';
import { AppSelectors } from 'src/app/global/state';
import { CscClient, CscStyles, FirebaseUser, Receipt } from 'src/app/global/state/models/models';
import { PtActions, PtSelectors } from '../state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { messageObject } from 'src/app/global/components/add-account/add-account.component';

@Component({
  selector: 'app-parking-ticket-receipts',
  templateUrl: './parking-ticket-receipts.component.html',
  styleUrls: ['./parking-ticket-receipts.component.scss']
})
export class ParkingTicketReceiptsComponent implements OnInit {

  receipts$: Observable<Receipt[]>;
  styles$: Observable<CscStyles>;
  selectedReceipt$: Observable<Receipt>;
  city: CscClient;
  subscriptions: Subscription[] = [];
  logoImageUrl: Observable<string>;
  receipt: Receipt;
  receipts: Receipt[];
  cityInfo$: Observable<CscClient>;
  receiptEmailForm: FormGroup;
  messageObject: messageObject;
  selectedReceipt: Receipt;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private receiptPdfService: ReceiptPrintService
  ) {
    this.styles$ = this.store.select(AppSelectors.getStyles);
    const citySubscription = this.store.select(AppSelectors.getCityContext).subscribe(city => this.city = city);
    this.subscriptions.push(citySubscription);
    this.cityInfo$ = this.store.select(AppSelectors.getCityContext);
    this.selectedReceipt$ = this.store.select(PtSelectors.getReceipt);
    const receiptSubscription = this.selectedReceipt$.subscribe(receipt => this.selectedReceipt = receipt);
    this.subscriptions.push(receiptSubscription);
  }

  ngOnInit(): void {
    if (this.selectedReceipt != undefined) {
      this.store.dispatch(PtActions.loadParkingTicketReceipts({ cityLongCode: localStorage.getItem("city"), receiptEmail: this.selectedReceipt.receiptEmail }));
      this.receipts$ = this.store.select(PtSelectors.getReceipts);
      this.receiptEmailForm = this.fb.group({
        receiptEmail: [this.selectedReceipt.receiptEmail, Validators.required]
      });
    }
    else {
      this.receiptEmailForm = this.fb.group({
        receiptEmail: ['', Validators.required]
      });
    }

    this.messageObject = { message: '', isError: false };
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(PtActions.resetParkingTicketState());
  }

  searchForReceipts() {
    this.store.dispatch(PtActions.resetParkingTicketState());
    this.messageObject.message = "";
    this.messageObject.isError = false;
    this.store.dispatch(PtActions.loadParkingTicketReceipts({ cityLongCode: localStorage.getItem("city"), receiptEmail: this.receiptEmailForm.value.receiptEmail }));
    this.receipts$ = this.store.select(PtSelectors.getReceipts);
  }

  onSelectReceipt(receipt: Receipt): void {
    this.selectedReceipt = receipt;
  }

  onCreateReceiptPdf(): void {

    const subscriptionLogo = this.store.select(AppSelectors.getLogoRef)
      .subscribe(ref => {

        let logoPath = ref.split("/");

        let logoUrl = "https://storage.googleapis.com/amiga-reporting-images/" + this.city.clientCode + "/" + logoPath[1];

        console.log("logoUrl", logoUrl);

        const subscriptionStyle = this.styles$.subscribe(style => {
          if (style) {
            //pass the logo url as string and style object to receipt pdf service
            this.receiptPdfService.createReceiptPdf(this.receipt, logoUrl, style);
          }
        }
        )
        this.subscriptions.push(subscriptionStyle);
      })
    this.subscriptions.push(subscriptionLogo);
  }
}
