<div class="full-width">
    <ng-container *ngIf="(availableTasks$|async)?.length > 0" #workflowcontainer>
        <mat-accordion [multi]="true">

            <ng-container *ngFor="let task of (availableTasks$|async)">
                
                <ng-container *ngIf="task.type === 'Approval'">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="h4">
                                    Step - {{task.description}}
                                    <mat-divider></mat-divider>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-approval-task></app-approval-task>
                    </mat-expansion-panel>
                </ng-container>

                <ng-container *ngIf="task.type === 'Confirmation'">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="h4">
                                    Step - {{task.description}}
                                    <mat-divider></mat-divider>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-confirmation-task></app-confirmation-task>
                    </mat-expansion-panel>
                </ng-container>

                <ng-container *ngIf="task.type === 'Form'">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="h4">
                                    Step - {{task.description}}
                                    <mat-divider></mat-divider>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-form-task [formTemplate]="task.formTemplateData"
                            [instanceId]="(workflowInstance$|async)?._id" [taskId]="task.id">
                        </app-form-task>
                    </mat-expansion-panel>
                </ng-container>

                <ng-container *ngIf="task.type === 'Fee'">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="h4">
                                    Step - {{task.description}}
                                    <mat-divider></mat-divider>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-payment-task [task]="task"
                        [instanceId]="(workflowInstance$|async)?._id"></app-payment-task>
                    </mat-expansion-panel>
                </ng-container>

                <ng-container *ngIf="task.type === 'FileUpload'">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="h4">
                                    Step - {{task.description}}
                                    <mat-divider></mat-divider>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-upload-task></app-upload-task>
                    </mat-expansion-panel>
                </ng-container>

                <ng-container *ngIf="task.type === 'AlgoliaValidation'">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="h4">
                                    Step - {{task.description}}
                                    <mat-divider></mat-divider>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <app-validation-task 
                        [task]="task"
                        [instanceId]="(workflowInstance$|async)?._id"
                        [apiKey]="(workflowInstance$|async)?.config?.algolia?.apiKey"
                        [appId]="(workflowInstance$|async)?.config?.algolia?.appId" 
                        [indexName]="task.indexName"
                        [routing]=true></app-validation-task>
                    </mat-expansion-panel>
                </ng-container>

            </ng-container>
        </mat-accordion>
    </ng-container>

    <ng-container *ngIf="(availableTasks$|async)?.length === 0" #workflowcontainerloading>
        <mat-progress-bar mode="indeterminate">
        </mat-progress-bar>
    </ng-container>
</div>