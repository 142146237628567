/**
 * Context Service is used for the following:
 * - retrieving user data from Firebase "Users" collection
 * - retrieving user data from Firebase "Citizens" collection
 * - retrieving city context from Firebase "CitizenServiceCloud" collection
 */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseUser, Citizen, CscClient, UserPreferences, AccountMaster, SubledgerAccount, ClientCity, Receipt } from '../state/models/models';
import { defaultStateFn, Store } from '@ngrx/store';
import * as AppSelectors from '../../global/state/app.selectors';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})

export class ContextService {

  citizen: Citizen;

  constructor(
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private store: Store,
    private fxns: AngularFireFunctions
  ) {
    this.store.select(AppSelectors.getCitizen).subscribe(citizen => this.citizen = citizen);
  }

  /**
   * Gets:
   * - uid of logged in user from AngularFireAuth
   * and then uses that to retrieve the user data from the "Users" collection
   * 
   * @returns Observable<FirebaseUser>
   */
  loadClientCities(): Observable<CscClient[]> {
    return this.db.collection<CscClient>(`CitizenServiceCloud`).valueChanges();
  }

  /**
   * Gets:
   * - uid of logged in user from AngularFireAuth
   * and then uses that to retrieve the user data from the "Users" collection
   * 
   * @returns Observable<FirebaseUser>
   */
  loadFirebaseUser(): Observable<FirebaseUser> {
    return this.afAuth.user
      .pipe(
        mergeMap((user: firebase.default.User) =>
          this.db.doc<FirebaseUser>(`/Users/${user.uid}`).valueChanges())
      )
  }

  /**
   * Gets:
   * - cityLongCode from localStorage
   * - uid of logged in user from AngularFireAuth
   * and then uses that to retrieve the citizen data from the "Citizen" collection
   * in that city
   * 
   * @returns Observable<Citizen>
   */
  loadCitizen(): Observable<Citizen> {
    const cityLongCode = localStorage.getItem("city");
    return this.afAuth.user
      .pipe(
        mergeMap((user: firebase.default.User) =>
          this.db.doc<Citizen>(`/CitizenServiceCloud/${cityLongCode.toUpperCase()}/Citizens/${user.uid}`).valueChanges())
      )
  }

  /**
   * Loads city context document from "CitizenServiceCloud" collection
   * 
   * @param cityLongCode {string} - cityLongCode for relevant city
   * @returns Observable<Citizen>
   */
  loadCityContext(cityLongCode: string): Observable<CscClient> {
    return this.db.doc<CscClient>(`/CitizenServiceCloud/${cityLongCode.toUpperCase()}`).valueChanges().pipe(take(1));
  }

  /**
   * Loads city account from "API" collection
   * 
   * @param cityLongCode {string} - cityLongCode for relevant city
   * @returns Observable<Citizen>
   */
  loadCityAccount(cityClientCode: string): Observable<string> {
    return this.db.doc<string>(`/Clients/${cityClientCode.toUpperCase()}/Api/STRIPE`).valueChanges().pipe(take(1));
  }

  /**
   * Updates a user's email/paper preferences in  "Users" collection
   * 
   * @param userPreferences {UserPreferences} - the user's email / paper preferences
   * @returns 
   */
  updateUserPreferences(preferences: UserPreferences) {
    console.log('preferences => ', preferences);
    return this.afAuth.user
      .pipe(
        mergeMap((user: firebase.default.User) =>
          this.db.doc(`/Users/${user.uid}`).update({ userPreferences: preferences }))
      )
  }

  /**
   * Add a user account for property taxation or utility billing
   */
  addCitizenAccount(uid: string, cityLongCode: string, accountNumber: string, accessCode: string, subledger: string): Observable<any> {
    const data = {
      uid: uid,
      cityLongCode: cityLongCode,
      accountNumber: accountNumber,
      accessCode: accessCode,
      subledger: subledger,
    }

    console.log("addCitizenAccount ", data);
    const callable = this.fxns.httpsCallable('addAccountToCitizen');
    return callable(data).pipe(take(1));
  }

  /**
   * Add a user account for property taxation or utility billing
   */
  getCitizenReceipts(uid: string, cityLongCode: string): Observable<Receipt[]> {
    return this.db.collection<Receipt>(`/CitizenServiceCloud/${cityLongCode.toUpperCase()}/Citizens/${uid}/ReceiptsCitizen`).valueChanges();
  }
}
