<div class="app-page">

    <div class="title">
        Receipts
        <hr>
    </div>

    <ng-container *ngIf="(receipts$ | async)?.length == 0">
        <div class="h2">You don't have any receipts to view.
        </div>
    </ng-container>

    <ng-container *ngIf="(receipts$ | async)?.length > 0">
        <div class="csc-card-row">
            <div class="csc-account-list-card">
                <div class="csc-card-header">
                    Your Receipts
                </div>
                <div class="csc-card-body csc-card-center-text receipt-list-container">
                    <mat-button-toggle-group [vertical]="true">
                        <mat-button-toggle *ngFor="let receipt of (receipts$ | async)"
                            (click)="onSelectReceipt(receipt)">
                            Receipt Date {{ receipt.receiptPaidAt.toDate() | date }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
            <div class="csc-account-detail-card">
                <div class="csc-card-header">
                    Your Receipt Details
                    <ng-container *ngIf="selectedReceipt">
                        <span> {{ selectedReceipt.receiptAmount /100 | currency}}</span>
                    </ng-container>
                </div>

                <ng-container *ngIf="!selectedReceipt">
                    <div class="csc-card-body csc-card-center-text">
                        Please select receipt on the left to see details
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedReceipt">
                    <div class="icon-with-text">
                        <table id="receipt" width="100%">
                            <thead [ngStyle]="(styles$|async)?.background">
                                <tr>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of selectedReceipt.receiptCartItems">
                                    <td>
                                        <p>{{item.cartItemInvoiceIdToPay}}</p>
                                    </td>
                                    <td class="text-right vertical-middle">{{item.cartItemAmountToPay / 100 | currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-right">Processing fee cost:</td>
                                    <td class="text-right">{{selectedReceipt.receiptProcessingFeeCost / 100 | currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-right">Receipt Total:</td>
                                    <td class="text-right">{{selectedReceipt.receiptAmount / 100 | currency}}</td>
                                </tr>
                                <tr>
                                    <td class="text-right">&nbsp;</td>
                                    <td class="text-right">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td class="text-right">Receipt ID:</td>
                                    <td class="text-right">{{selectedReceipt._id }}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="csc-card-header">
                        <button mat-button (click)="onCreateReceiptPdf()">Click here for a printable version</button>
                    </div>
                </ng-container>

            </div>
        </div>
    </ng-container>
</div>