import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approval-task',
  templateUrl: './approval-task.component.html',
  styleUrls: ['./approval-task.component.scss']
})
export class ApprovalTaskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
