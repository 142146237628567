import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { LoginService } from "src/app/global/services/login.service";
import { Invoice } from "src/app/global/state/models/models";
import { ParkingTicketsService } from '../services/parking-tickets.service';
import { PtActions} from '../state'

@Injectable()
export class PtEffects {

    constructor(
        private actions$: Actions,
        private db: ParkingTicketsService,
        private store: Store,
        private login: LoginService
    ) { }

   
    loadPtInvoicesByTicketNumber$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PtActions.loadParkingTicketsByTicketNumber),
            mergeMap(action => this.db.getParkingTicketsByTicketNumber(action.cityLongCode,action.ticketNumber,action.paymentStatus).pipe(
                map(invoices => PtActions.loadParkingTicketsByTicketNumberSuccess({ invoices })),
                catchError(error =>
                    of(PtActions.loadParkingTicketsByTicketNumberFailure({ error })))
            ))
        )
    })
   
    loadPtInvoicesByLicensePlate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PtActions.loadParkingTicketsByLicensePlate),
            mergeMap(action => this.db.getParkingTicketsByLicensePlate(action.cityLongCode,action.licensePlate,action.paymentStatus).pipe(
                map(invoices => PtActions.loadParkingTicketsByLicensePlateSuccess({ invoices })),
                catchError(error =>
                    of(PtActions.loadParkingTicketsByLicensePlateFailure({ error })))
            ))
        )
    })

    loadPtReceipts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PtActions.loadParkingTicketReceipts),
            mergeMap(action => this.db.getParkingTicketReceipts(action.cityLongCode,action.receiptEmail).pipe(
                map(receipts => PtActions.loadParkingTicketReceiptsSuccess({ receipts })),
                catchError(error =>
                    of(PtActions.loadParkingTicketsByLicensePlateFailure({ error })))
            ))
        )
    })

}