import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BlState } from './bl.reducer';


const getBlFeatureState = createFeatureSelector<BlState>('blState');

/**
 * utility billing selectors
 */

 export const getBlTabs = createSelector(
    getBlFeatureState,
    state => state.blTabs
 );

 export const getBlInvoices = createSelector(
    getBlFeatureState,
    state => state.blInvoices
 );

 export const getSelectedAccount = createSelector(
   getBlFeatureState,
   state => state.blSelectedAccount
);

export const getSelectedInvoice = createSelector(
   getBlFeatureState,
   state => state.blSelectedInvoice
);

export const getSelectedAccountInvoices = createSelector(
   getBlFeatureState,
   state => state.blSelectedAccountInvoices
);