import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CscStyles, FirebaseUser, CscClient, CartItem, ShoppingCart, Invoice } from 'src/app/global/state/models/models';
import * as AppSelectors from '../../../global/state/app.selectors';
import * as AppActions from '../../../global/state/app.actions';
import { PtActions, PtSelectors } from '../state'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { messageObject } from 'src/app/global/components/add-account/add-account.component';
import { Router } from '@angular/router';
import { EmptyCartComponent } from 'src/app/global/components/shopping-cart/empty-cart/empty-cart.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-parking-tickets-search',
  templateUrl: './parking-tickets-search.component.html',
  styleUrls: ['./parking-tickets-search.component.scss']
})
export class ParkingTicketsSearchComponent implements OnInit {

/**
   * @param styles$ - an observable of the CscStyles for the city
   * @param user$ - an observable of the user for the session
   * @param user - a variable of the user for the session
   * @param citizen$ - an observable of the citizen for the session
   * 
   */
 styles$: Observable<CscStyles>;

 cityInfo$: Observable<CscClient>;
 shoppingCart: ShoppingCart;

 subscriptions: Subscription[] = [];
 searchParkingTicketsForm: FormGroup;
 error: string = '';
 error$ = new BehaviorSubject('');
 searchForInvoices: Invoice[] = [];
 searchForInvoices$: Observable<Invoice[]>;

 messageObject: messageObject;

 shoppingCart$: Observable<ShoppingCart>;
 user$: Observable<FirebaseUser>;
 subscriptons: Subscription[] = [];
 

 constructor(
   private store: Store,
   private fb: FormBuilder,
   private router: Router,
   private dialog: MatDialog,
 ) {
   let errorSubscription = this.store.select(AppSelectors.getError)
     .subscribe(error => this.error$.next(error));
     this.subscriptions.push(errorSubscription);

   this.styles$ = this.store.select(AppSelectors.getStyles);
   this.cityInfo$ = this.store.select(AppSelectors.getCityContext);
   this.shoppingCart$ = this.store.select(AppSelectors.getShoppingCart);
   const shoppingCartSubscription = this.shoppingCart$.subscribe(cart => this.shoppingCart = cart);
   this.subscriptons.push(shoppingCartSubscription);
 }

 /**
  * initialize parking tickets form
  */
 ngOnInit(): void {
   this.searchParkingTicketsForm = this.fb.group({
     searchBy: ['ticketNumber', Validators.required],
     ticketNumberLicensePlate: ['', Validators.required],
     //paymentStatus: ['Y', Validators.required]
   });
   this.messageObject = { message: '', isError: false };
   //let clientCode: string = localStorage.getItem('code');
   //console.log("Client code is ",clientCode);
   //this.store.dispatch(AppActions.loadCityAccount({ cityClientCode: clientCode }));
   this.store.dispatch(PtActions.resetParkingTicketState());
 }

 /**
  * clear all the RXJS subscriptions
  */
 ngOnDestroy(): void {
   this.subscriptions.forEach(subscription => subscription.unsubscribe());
   this.store.dispatch(PtActions.resetParkingTicketState());
 }

 showShoppingCart(): void {
  if (this.shoppingCart.shoppingCartItemsCount > 0) {
    this.router.navigateByUrl('/shopping-cart-unauthenticated');
  } else {
    this.dialog.open(
      EmptyCartComponent,
      {
        width: '500px'
      }
    )
  }
}

 /**
  * 
  * @param invoice the invoice to be added to the shopping cart
  * @param amount the amount of the invoice to be paid.  If amount is not passed, then the entire unpaid amount of the invoice is added to the cart
  */
 addItemToShoppingCart(invoice: Invoice, amount?: number) {
  console.log("adding item to shopping cart");
   let invoiceIndex = this.shoppingCart.shoppingCartItems.findIndex(item => item.cartItemInvoiceIdToPay == invoice._id);
   // if index==-1, then invoice is NOT already in shopping cart
   if (invoiceIndex == -1) {
     const amountToPay = amount ? amount * 100 : (invoice.invoiceAmountDue - invoice.invoiceAmountPaid);
     let cartItem: CartItem = {
       cartItemAmountToPay: amountToPay,
       cartItemInvoiceIdToPay: invoice._id,
       cartItemDescription: "Parking Ticket Payment",
       cartItemAccountNumber: invoice.invoiceData.invoiceAccountNumber,
       cartItemSubledger: "PT",
       cartItemInvoiceCreatedAt: Date.now()
     };
     this.store.dispatch(AppActions.addItemToShoppingCart({ item: cartItem, subledger: "PT" }))
   }
 }

 searchForTickets(): void 
 {
   this.messageObject.message = "";
   this.messageObject.isError = false;
   //ticketNumberLicensePlate
   console.log("search by",this.searchParkingTicketsForm.value.searchBy,"license or ticket number",this.searchParkingTicketsForm.value.ticketNumberLicensePlate);

   //search by license plate
   if(this.searchParkingTicketsForm.value.searchBy == "licensePlate")
   {
     console.log("local storage city",localStorage.getItem("city"));
     let licensePlate = this.searchParkingTicketsForm.value.ticketNumberLicensePlate.toUpperCase();
     this.store.dispatch(PtActions.loadParkingTicketsByLicensePlate({ cityLongCode: localStorage.getItem("city"), licensePlate: licensePlate, paymentStatus: "Y" } ));
     this.searchForInvoices$ = this.store.select(PtSelectors.getSearchInvoices);
     const searchForInvoicesSubscription = this.searchForInvoices$.subscribe(invoices => {
       this.searchForInvoices = invoices
       if(invoices.length == 0)
       {
         this.messageObject.message = "No tickets matched your search.";
       }
       else
       {
         this.messageObject.message = "";
       }
     });
     this.subscriptions.push(searchForInvoicesSubscription);
   }
   else //search by ticket number
   {
     console.log("local storage city",localStorage.getItem("city"));
     this.store.dispatch(PtActions.loadParkingTicketsByTicketNumber({ cityLongCode: localStorage.getItem("city"), ticketNumber: this.searchParkingTicketsForm.value.ticketNumberLicensePlate, paymentStatus: "Y" } ));
     this.searchForInvoices$ = this.store.select(PtSelectors.getSearchInvoices);
     const searchForInvoicesSubscription = this.searchForInvoices$.subscribe(invoices => {
       this.searchForInvoices = invoices
       if(invoices.length == 0)
       {
         this.messageObject.message = "No tickets matched your search.";
       }
       else
       {
         this.messageObject.message = "";
       }
     });
     this.subscriptions.push(searchForInvoicesSubscription);
   }
 }

}
